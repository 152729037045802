import { POPUP_PRIORITY } from 'constants/PopupPriority';
import { createContext, useContext, useState } from 'react';

/**
 * @typedef PopupContext
 * @property {boolean} open - true = popup is opened
 * @property {import('react').SetStateAction<boolean>} setOpen
 * @property {boolean} canShowNextPopup - true = popup is fetching popupData
 * @property {import('react').SetStateAction<boolean>} setCanShowNextPopup
 */

/**
 * @type {import('react').Context<PopupContext>}
 */
const PopupContext = createContext({});

// POPUP_PRIORITY = ['REMIND_PAYMENT', 'REMIND_OCR', 'MAR_COM', 'RATING_ORDER', 'EXPIRED_LOYALTY'];

export function PopupProvider({ children }) {
  const [activePopup, setActivePopup] = useState('');
  // call this function to set next active popup, the index depend on POPUP_PRIORITY or data you setup
  const setCanShowNextPopup = () => {
    const findIndex = POPUP_PRIORITY.findIndex((x) => x === activePopup);
    setActivePopup(POPUP_PRIORITY[findIndex + 1]);
  };

  return (
    <PopupContext.Provider
      value={{
        setActivePopup,
        activePopup,
        setCanShowNextPopup,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
}

export const usePopup = () => useContext(PopupContext);
