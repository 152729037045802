const NotiReducer = (state, action) => {
  const { notification } = state;
  switch (action.type) {
    case 'INIT_WEB_SOCKET_SUCCESS':
      return {
        ...state,
        initSocket: true,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        notification: {
          important: [...action.payload.notification.important],
          product:  [...action.payload.notification.product],
          promotion: [...action.payload.notification.promotion],
        },
        unread: action.payload.unread,
        total: action.payload.total,
        loading: false,
        numberNotifyWithTab: {
          important: {
            total: action.payload.importantNotify.total,
            unread: action.payload.importantNotify.unread
          },
          product: {
            total: action.payload.productNotify.total,
            unread: action.payload.productNotify.unread
          },
          promotion: {
            total: action.payload.promotionNotify.total,
            unread: action.payload.promotionNotify.unread
          }
        }
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        notification: {
          important: [],
          product: [],
          promotion: []
        },
        unread: 0,
        total: 0,
        loading: false,
        numberNotifyWithTab: {
          important: {
            total: 0,
            unread: 0
          },
          product: {
            total: 0,
            unread: 0
          },
          promotion: {
            total: 0,
            unread: 0
          }
        }
      };
    case 'GET_NOTIFICATIONS':
      return {
        ...state,
        notification: {
          important: notification.important,
          product:  notification.product,
          promotion: notification.promotion,
        }
      };
    case 'CLEAR_ALL':
      return {
        ...state,
        notification: {
          important: [],
          product: [],
          promotion: []
        },
        unread: 0,
        total: 0,
        numberNotifyWithTab: {
          important: {
            total: 0,
            unread: 0
          },
          product: {
            total: 0,
            unread: 0
          },
          promotion: {
            total: 0,
            unread: 0
          }
        }
      };
      case 'COUNT_SUCCESS':
      return {
        ...state,
        unread: action.payload.unread,
        total: action.payload.total,
        loading: false,
      };
    default:
      return state;
  }
};

export default NotiReducer;
