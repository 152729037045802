import { HTTP_STATUS } from 'constants/Enums/http';
import { PreventSearchKeywords, PreventSearchKeywordsAlias } from 'constants/data/search';
import { IS_ENCRYPTED_PRICE } from 'sysconfig';
import { sanitize } from 'utils/ObjectUtils';
import { changeAlias } from 'utils/StringUtils';
import { GET, GET_ALL, POST, getData } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';
const ORDER_API_PREFIX = '/marketplace/order/v2';
const PRODUCT_API_PREFIX = '/marketplace/product/v2';

export async function loadDataMostSearch(ctx) {
  const url = '/product/most-search';
  const result = await GET({ url, ctx });

  return getData(result);
}

export async function loadDataManufacturer(ctx) {
  const res = await GET_ALL({ url: `${PRODUCT_API_PREFIX}/manufacturer/list`, ctx, isBasic: true });
  return getData(res)?.sort((a, b) => (a?.name?.toLowerCase() || '').localeCompare(b?.name?.toLowerCase() || ''));
}

export const loadDataManufacturerMock = async () =>
  GET({
    url: '/web/manufactuers',
    mock: true,
    isAuth: false,
  });

export const getTagNameFromIds = async ({ tagIds }) =>
  GET({
    url: '/marketplace/product/v2/tag/list',
    params: { codes: tagIds, limit: 100 },
  });

// fix limit 20 of tabs -> converted
export const getTabs = async ({ ctx }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tabs/active`,
    params: { limit: 100 },
    isBasic: true,
  });

export const getDeals = async ({ ctx, params }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/products/deal/list`,
    params,
    isBasic: true,
  });

export const getDealsDetail = async ({ ctx, body, params }) =>
  POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/deal/list`,
    body,
    params,
    isBasic: true,
  });

export const getSettingTags = async ({ ctx, limit = 100 }) =>
  GET_ALL({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tag/list`,
    isBasic: true,
    limit,
  });

export const getSettingTagsMock = async ({ ctx }) =>
  GET({
    ctx,
    url: '/web/tags',
    mock: true,
    isAuth: false,
  });

export const getProducts = async ({ ctx, codes, limit, customerLevel, locationCode, getPrice = true, customerId }) =>
  POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/search/list`,
    body: { skus: codes, getProduct: true, locationCode, customerLevel, getPrice, customerId },
    params: { limit },
  });

export const getDataCollections = async ({ ctx }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/collection/page`,
    params: { q: 'MAIN_PAGE' },
    isBasic: true,
  });

export const getFilesProduct = async ({ ctx, refCode }) =>
  GET({
    ctx,
    url: `/core/file-manager/v1/file`,
    params: { q: JSON.stringify({ refType: 'PRODUCT', fileType: 'DOCUMENT', refCode }) },
    isBasic: true,
  });

export const getProductsByIds = async ({ ctx, ids, ...restProps }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/list`,
    params: { ids },
    ...restProps,
  });

export const getProductsByCodes = async ({ ctx, codes = [], ...restProps }) =>
  POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/list`,
    body: { codes },
    ...restProps,
  });

export const getDescriptionById = async ({ ctx, productId, ...restProps }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/description`,
    params: { q: JSON.stringify({ productID: productId }) },
    ...restProps,
  });

export const loadDiscovery = async ({ ctx, lastID = '' }) => {
  return POST({
    ctx,
    url: '/marketplace/social/v1/posts',
    body: {
      limit: 20,
      lastID,
    },
  });
};

export const getDescriptionBySku = async ({ ctx, sku }) => {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/description`,
    params: {
      q: JSON.stringify({
        code: sku.split('.')[1] || '',
      }),
    },
  });
};

export const getDetailProductWithoutLogin = async ({ ctx, provinceCode }) => {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/detail-raw`,
    params: {
      q: ctx.query.slug || null,
      provinceCode,
      queryOption: `isReplacePriceAfterVoucher`,
    },
    provinceCode,
    isBasic: true,
  });
};

export const getSkusLimit = async ({ ctx, skuCodes }) =>
  POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku-limit/list`,
    body: {
      skuCodes,
    },
  });

export const getSkusBuyed = async ({ ctx, skuCodes }) =>
  POST({
    ctx,
    url: `${ORDER_API_PREFIX}/sku-limit/history-today`,
    body: {
      skuCodes,
    },
  });

export const getSkusByProductId = async ({ productIds, ctx }) => {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku-item/list`,
    params: {
      productIds,
    },
    isBasic: true,
  });
};

// without ctx
export const getFuzzySearchClient = async ({ body, ctx, ...restProps }) => {
  if (body && body.extraTag && body.filter && body.filter.tag) {
    body.filter.tags = [body.extraTag, body.filter.tag];
    delete body.filter.tag;
  } else if (body.extraTag) {
    body.filter.tag = body.extraTag;
  }
  const { text } = body || {};
  if (text) {
    const textSearch = text.toLocaleLowerCase().trim();
    const lengthTextSearch = textSearch?.split(' ').length;
    if (
      (lengthTextSearch === 1 && PreventSearchKeywords.indexOf(textSearch) >= 0) ||
      textSearch.toLocaleLowerCase().startsWith('http') ||
      PreventSearchKeywordsAlias.indexOf(changeAlias(textSearch)) >= 0
    ) {
      return { status: HTTP_STATUS.NotFound, msg: 'Keyword is not valid' };
    }
  }
  if (typeof body.isAvailable === 'string') {
    // eslint-disable-next-line no-param-reassign
    body.isAvailable = body.isAvailable === 'true';
  }

  const urlSearch = IS_ENCRYPTED_PRICE ? `${FRONTEND_APIS_PREFIX}/search/fuzzy-encrypted` : `${FRONTEND_APIS_PREFIX}/search/fuzzy`;

  if (restProps.v2) {
    delete restProps.v2;
    body.queryOption = {
      ...body.queryOption,
      consumedMaxQuantity: true,
      sellerInfo: true,
      isGetSalePriceLabel: restProps.isGetSalePriceLabel || false,
      quantityInCart: true,
      getWishList: true,
      getShadowSKU: !!restProps.isQuickOrder,
      isReplacePriceAfterVoucher: true,
      isGetSKUReplace: true,
    };
    //
    if (body.filter?.categoryFilters?.length) {
      for (let i = 0; i < body.filter.categoryFilters.length; i += 1) {
        if (body.filter.categoryFilters[i].efficacyCodes?.length) {
          body.filter.categoryFilters[i].categoryCodes = [];
          body.filter.categoryFilters[i].code = '';
        }
      }
    }
    //

    return POST({
      ctx,
      url: urlSearch,
      body,
      ...restProps,
    });
  }

  // if (IS_ENCRYPTED_PRICE) {
  body.queryOption = {
    ...body.queryOption,
    // isRawFuzzy: true,
    quantityInCart: true,
    getWishList: true,
    isReplacePriceAfterVoucher: true,
  };
  // }

  return POST({ ctx, url: urlSearch, body, ...restProps });
};
const getDataProductList = async ({ body, ctx, ...restProps }) => {
  if (body?.extraTag && body?.filter?.tag) {
    body.filter.tags = [body.extraTag, body.filter.tag];
    delete body.filter.tag;
  } else if (body?.extraTag) {
    body.filter.tag = body.extraTag;
  }
  const { text } = body || {};
  if (text) {
    const textSearch = text.toLocaleLowerCase().trim();
    const lengthTextSearch = textSearch?.split(' ').length;
    if (
      (lengthTextSearch === 1 && PreventSearchKeywords.indexOf(textSearch) >= 0) ||
      textSearch.toLocaleLowerCase().startsWith('http') ||
      PreventSearchKeywordsAlias.indexOf(changeAlias(textSearch)) >= 0
    ) {
      return { status: HTTP_STATUS.NotFound, msg: 'Keyword is not valid' };
    }
  }
  if (typeof body.isAvailable === 'string') {
    body.isAvailable = body.isAvailable === 'true';
  }

  body.queryOption = {
    ...body.queryOption,
    quantityInCart: true,
    getWishList: true,
    isReplacePriceAfterVoucher: true,
  };

  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/screen/product/list`,
    body,
    ...restProps,
  });
};

export const getProductGroupsClient = async () => {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/featured-product-group`,
    body: {
      limit: 15,
      q: {
        productGroups: [
          {
            index: 1,
            tags: ['RENHAT'],
          },
          // {
          //   index: 2,
          //   tags: ['GY7G'],
          // },
          // {
          //   index: 3,
          //   tags: ['BANCHAY'],
          // },
          {
            index: 4,
            tags: ['V2U1'],
          },
          {
            isDeal: true,
            index: 5,
          },
        ],
        isReplacePriceAfterVoucher: true,
      },
    },
  });
};

export const getProductRecommendation = async ({ ctx, offset = 0, limit = 15, skusNotIn }) => {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/featured-product-group`,
    body: {
      offset,
      limit,
      q: {
        productGroups: [
          {
            isRecommendation: true,
            index: 1,
          },
        ],
        skusNotIn,
        isReplacePriceAfterVoucher: true,
      },
    },
  });
};

export const getProductQuickOrder = async ({ offset = 1, text, limit, isReplacePriceAfterVoucher = false, isGetSKUReplace = false }) => {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/quick-order/fuzzy-encrypted`,
    body: {
      offset,
      text,
      limit,
      isReplacePriceAfterVoucher,
      queryOption: {
        isReplacePriceAfterVoucher: true,
        isGetSKUReplace,
      },
    },
  });
};

export const getCartBySeller = async (isFistTimeGetCart = false, promosRemoved = '') => {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/screen/cart/info`,
    params: {
      queryOption: 'price,consumedMaxQuantity,sellerInfo,isGetSKUReplace',
      getVoucherAuto: !!isFistTimeGetCart,
      redeemCodeRemovedStr: promosRemoved || '',
    },
  });
};

export const getBlockList = async ({ ctx, params }) => {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/screen/product/block`,
    params,
  });
};

export const getReplaceProduct = async ({ offset = 0, limit = 10, slug = '', sku }) => {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/search/substitutes-encrypted`,
    body: sanitize({
      sku,
      offset,
      limit,
      slug,
    }),
  });
};

export default {
  loadDataMostSearch,
  loadDataManufacturer,
  loadDataManufacturerMock,
  getTabs,
  getSettingTags,
  getSettingTagsMock,
  getDeals,
  getDealsDetail,
  getProducts,
  getDataCollections,
  getProductsByIds,
  getProductsByCodes,
  getFilesProduct,
  getDescriptionById,
  loadDiscovery,
  getDescriptionBySku,
  getDetailProductWithoutLogin,
  getSkusLimit,
  getSkusBuyed,
  getTagNameFromIds,
  getFuzzySearchClient,
  getSkusByProductId,
  getProductGroupsClient,
  getProductRecommendation,
  getProductQuickOrder,
  getCartBySeller,
  getDataProductList,
};
