import { TopManufacturer } from 'constants/manufacturer';
import { GET, GET_ALL, getData, isValid } from './Clients';

const PRODUCT_API_PREFIX = '/marketplace/product/v2';

export async function loadBrand({ ctx, params }) {
  const res = await GET_ALL({ url: `${PRODUCT_API_PREFIX}/manufacturer/list`, ctx, isBasic: true, params });
  if (!isValid(res)) {
    return [];
  }

  return res?.data
    ?.filter((item) => TopManufacturer.indexOf(item.code) >= 0)
    .sort((a, b) => (a?.name?.toLowerCase() || '').localeCompare(b?.name?.toLowerCase() || ''));
}

export async function loadGroup({ ctx, params }) {
  const res = await GET({ url: `${PRODUCT_API_PREFIX}/category/list`, ctx, isBasic: true, params });
  if (!isValid(res)) {
    return [];
  }

  return res?.data?.sort((a, b) => (a?.name?.toLowerCase() || '').localeCompare(b?.name?.toLowerCase() || ''));
}

export const loadGroupMock = async ({ ctx }) => GET({ url: '/web/categories', ctx, mock: true, isAuth: false });

export async function loadEfficacyList({ ctx, params }) {
  const res = await GET({ url: `${PRODUCT_API_PREFIX}/efficacy/list`, ctx, isBasic: true, params });
  return getData(res);
}

export async function loadEfficacyCombinedList({ ctx, params }) {
  const res = await GET({ url: `${PRODUCT_API_PREFIX}/efficacy-combined/list`, ctx, isBasic: true, params });
  return getData(res);
}

export async function loadSellerCategoryList({ ctx, params }) {
  const res = await GET({ url: `${PRODUCT_API_PREFIX}/seller-category`, ctx, isBasic: true, params });
  return getData(res);
}

export async function loadCategoryInfoBySlug(ctx) {
  const { query } = ctx;
  const params = {
    q: JSON.stringify({ slug: query.slug }),
  };

  const res = await GET({
    url: `${PRODUCT_API_PREFIX}/category`,
    ctx,
    params,
    isBasic: true,
  });
  return getData(res);
}

export async function loadManufacturerInfoBySlug(ctx) {
  const { query } = ctx;
  const params = {
    q: JSON.stringify({ slug: query.slug }),
  };

  const res = await GET({
    url: `${PRODUCT_API_PREFIX}/manufacturer`,
    ctx,
    params,
    isBasic: true,
  });
  return getData(res);
}

export async function loadTagInfoBySlug(ctx) {
  const { query } = ctx;
  const params = {
    q: JSON.stringify({ slug: query.slug }),
  };
  const res = await GET({
    url: `${PRODUCT_API_PREFIX}/tag/list`,
    ctx,
    isBasic: true,
    params,
  });

  return getData(res);
}

export async function loadTabDetail({ ctx, params }) {
  return GET({
    url: `${PRODUCT_API_PREFIX}/tabs/active`,
    ctx,
    isBasic: true,
    params,
  });
}

export default {
  loadTagInfoBySlug,
  loadTabDetail,
  loadEfficacyList,
  loadEfficacyCombinedList,
  loadSellerCategoryList,
};

