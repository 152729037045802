export const mapShortNames = {
  'Thành Phố HCM': 'TPHCM',
  'Hà Nội': 'HN',
  'TP Hà Nội': 'HN',
  'Miền Nam': 'MN',
  'Trung Ương': 'TW',
  'Việt Nam': 'VN',
  'Hộ Kinh Doanh': 'HKD',
  'Chi Nhánh': 'CN',
  'Y Tế': 'YT',
  'Một Thành Viên': 'MTV',
  'Vật Tư Y Tế': 'VTYT',
  'Nhà cung cấp': 'NCC',
  'Trang Thiết Bị Y Tế': 'TTBYT',
  'Trang TBYT': 'TTBYT',
  'Thương Mại Và Dịch Vụ': 'TMDV',
  'TM-DV': 'TMDV',
  'Đầu Tư và Phát Triển': 'ĐTPT',
  'Kinh Doanh tổng hợp': 'KDTH',
  'Dụng Cụ Y Tế ': 'DCYT',
  'Dược Phẩm': 'DP',
  'Công Nghệ': 'CN',
  'Y Học Cổ Truyền': 'YHCT',
  'Xuất Nhập Khẩu': 'XNK',
  'Xuất khẩu': 'XK',
  'Dược Mỹ Phẩm': 'DMP',
  'Doanh Nghiệp Tư Nhân': 'DNTN',
  'Sản xuất': 'SX',
  'Hóa Mỹ Phẩm': 'HMP',
  'Đại lý': 'ĐL',
  'Đại lý phân phối': 'ĐLPP',
  'Hợp tác xã Nông nghiệp công nghệ cao': 'HTXNNCNC',
  'Trung Tâm DV Y Tế Tổng Hợp': 'TTDVYTTH',
  'Cửa hàng': 'CH',
  'Công ty Cổ phần': 'CTCP',
  'Công ty CP': 'CTCP',
  'CTY Cổ Phần': 'CTCP',
  'Công Ty Cổ Phẩn Quốc Tế': 'CTCPQT',
  'Công ty CP quốc tế': 'CTCPQT',
  'Công Ty CP Dược': 'CTCPD',
  'Công Ty Cổ Phần Dược': 'CTCPD',
  'Công ty Cổ phần Dược phẩm': 'CTCPDP',
  'Công ty Cổ Phần Dược Vật Tư Y Tế': 'CTCPDVTYT',
  'Công Ty CP Dược Phẩm': 'CTCPDP',
  'Công Ty CP Dược Phẩm Công Nghệ Cao': 'CTCPDPCNC',
  'Công Ty CP Dược Phẩm Quốc Tế': 'CTCPDPQT',
  'Công ty CP DP QT': 'CTCPDPQT',
  'Công Ty Cổ Phần Dược Liệu': 'CTCPDL',
  'Công Ty CP Dược Liệu Và Thiết Bị Y Tế': 'CTCPDLTBYT',
  'Công Ty CP Dược & TBYT': 'CTCPDTBYT',
  'Công Ty Cổ Phần Đông Dược': 'CTCPĐD',
  'Công Ty Cổ Phần Đông Nam Dược': 'CTCPĐND',
  'Công ty Cổ phần Hóa-Dược phẩm': 'CTCPHDP',
  'Công ty Cổ phần Đầu tư': 'CTCPDĐT',
  'Công Ty CPĐT': 'CTCPDĐT',
  'Công ty Cổ Phần Đầu Tư Dược Phẩm': 'CTCPĐTDP',
  'Công ty Cổ Phần Đầu Tư Kinh Doanh Dược Phẩm': 'CTCPĐTKDDP',
  'Công Ty Cổ Phần Đầu Tư Và Phát Triển': 'CTCPĐTPT',
  'Công Ty CP Đầu Tư Và Phát Triển': 'CTCPĐTPT',
  'Công ty CP ĐTPT & TM': 'CTCPĐTPTTM',
  'Công Ty CP Đầu Tư Thương Mại': 'CTCPĐTTM',
  'Công ty CP đông nam dược': 'CTCPĐTDND',
  'Công ty CP Kinh Doanh và Phát Triển': 'CTCPKDPT',
  'Công Ty Cổ Phần Phát Triển & Đầu Tư Thương Mại': 'CTCPPTĐTTM',
  'Công ty Cổ Phần Giải Pháp Sức Khỏe và Sắc Đẹp': 'CTCPGPSKSĐ',
  'Công ty CPXNK': 'CTCPXNK',
  'Công Ty Cổ Phần XNK Y Tế': 'CTCPXNKYT',
  'Công ty CPTM XNK Dược liệu': 'CTCPXNKDL',
  'Công ty Cổ phần XNK Dược phẩm': 'CTCPXNKDP',
  'Công Ty Cổ Phần Sản Xuất Thương Mại Trang Thiết Bị Y Tế': 'CTCPSXTMTTBYT',
  'Công Ty Cổ Phần Sản Xuất Và Xuất Nhập Khẩu': 'CTCPSXXNK',
  'Công ty cổ phần sản xuất, thương mại và dịch vụ': 'CTCPSXTMDV',
  'Công ty Cổ phần dịch vụ & đầu tư thương mại': 'CTCPDVĐTTM',
  'Công ty Cổ phần Liên doanh Dược phẩm': 'CTCPLDDP',
  'Công ty Cổ phần Liên doanh Dược phẩm Quốc tế': 'CTCPLDDPQT',
  'Công Ty Cổ Phần Tập Đoàn': 'CTCPTĐ',
  'Công Ty CP Tập Đoàn Dược Phẩm': 'CTCPTĐDP',
  'Công Ty CP Tập Đoàn Dược Sức Khỏe và Sắc Đẹp': 'CTCPTĐDSKSĐ',
  'Công Ty Cổ Phần Thiết Bị': 'CTCPTB',
  'Công Ty CP TBYT': 'CTCPTBYT',
  'Công ty cổ phần thực phẩm dinh dưỡng hữu cơ': 'CTCPTPDDHC',
  'Công Ty Cổ Phần Thương Mại': 'CTCPTM',
  'Công Ty Cổ Phần TM': 'CTCPTM',
  'Công ty CPTM': 'CTCPTM',
  'Công Ty Cổ Phần Thương Mại & Thiết Bị Y Tế': 'CTCPTMTBYT',
  'Công Ty Cổ Phần TM Thiết Bị Y Tế': 'CTCPTMTBYT',
  'Công Ty CP Thương Mại Công Nghệ Dươc Phẩm': 'CTCPTMCNDP',
  'Công ty cổ phần thương mại dịch vụ được liệu': 'CTCPTMDVDL',
  'Công ty cổ phần Đầu tư, Thương mại và Dịch vụ': 'CTCPDTTMDV',
  'Công Ty CP TM Và ĐT': 'CTCPTMĐT',
  'Công Ty Cổ Phần Thương Mại Dịch Vụ': 'CTCPTMDV',
  'Công Ty CP Thương Mại Dịch Vụ': 'CTCPTMDV',
  'Công ty Cổ phần Thương mại Dịch vụ Tổng hợp': 'CTCPTMDVTH',
  'Công Ty Cổ Phần Thương Mại Dịch Vụ Dược Phẩm': 'CTCPTMDVDP',
  'Công ty Cổ phần Dược phẩm và Dịch vụ y tế': 'CTCPTMDVYT',
  'Công Ty CP TM DV Hóa Dược Và TBYT': 'CTCPTMDVHDTBYT',
  'Công Ty CP TM XNK Dược Phẩm': 'CTCPTMXNKDP',
  'Công Ty CP TM&SX Dược Phẩm': 'CTCPTMSXDP',
  'Công Ty Cổ Phần Thương Mại Dược Phẩm': 'CTCPTMDP',
  'Công Ty CP TM Dược Phẩm': 'CTCPTMDP',
  'Công Ty Cổ Phần Thương Mại Quốc Tế': 'CTCPTMQT',
  'Công Ty CP Thương Mại Và Y Tế': 'CTCPTMYT',
  'Công Ty Cổ Phần Thương Mại và Dịch Vụ MTK': 'CTCPTMDVMKT',
  'Công Ty Cổ phần Thương Mại Xuất Nhập Khẩu': 'CTCPTMXNK',
  'Công Ty Cổ Phần TM - XNK': 'CTCPTMXNK',
  'CÔNG TY CỔ PHẦN TM XUẤT NHẬP KHẨU': 'CTCPTMXNK',
  'Công Ty Cổ Phần Tinh Dầu Và Hương Liệu': 'CTCPTDHL',
  'Công Ty Cổ Phần Tổng Hợp': 'CTCPTH',
  'Công Ty Cổ Phần Trang Thiết Bị Y Tế': 'CTCPTTBYT',
  'Công Ty CP Trang Thiết Bị Y Tế': 'CTCPTTBYT',
  'Công Ty CP Công Nghệ Y Tế': 'CTCPCNYT',
  'Công Ty CP Tổ Hợp Y Tế Cổ Truyền Biến Chủng': 'CTCPTHYTCTBC',
  'Công ty CP Tư vấn và đầu tư dịch vụ y tế': 'CTCPTVĐTDVYT',
  'Công Ty Dược Mỹ Phẩm': 'CTDMP',
  'Công Ty TNHH': 'CTTNHH',
  'Cty TNHH': 'CTTNHH',
  'Công Ty TNHH Cao Dược Liệu': 'CTTNHHCDL',
  'Công ty TNHH Chăm sóc sắc đẹp': 'CTTNHHCSSK',
  'Công Ty TNHH Công Nghệ': 'CTTNHHCN',
  'Công Ty TNHH Công Nghệ Dược Phẩm': 'CTTNHHCNDP',
  'Công Ty TNHH Công nghệ Sinh học': 'CTTNHHCNSH',
  'Công Ty TNHH Công Nghệ Vật Tư Y Tế': 'CTTNHHVTYT',
  'Công Ty TNHH Dịch Vụ Đầu Tư Phát Triển Y Tế': 'CTTNHHDVĐTPTYT',
  'Công Ty TNHH Dịch Vụ Thương Mại Tổng Hợp': 'CTTNHHDVTMTH',
  'Công Ty TNHH Dịch Vu Tổng Hợp': 'CTTNHHDVTH',
  'Công Ty TNHH Dịch Vụ Xuất Nhập Khẩu Toàn Cầu': 'CTTNHHDVXNKTC',
  'Công Ty TNHH Dược': 'CTTNHHD',
  'Công Ty TNHH Dược Phẩm': 'CTTNHHDP',
  'Công Ty TNHH Dược Liệu': 'CTTNHHDL',
  'Công Ty TNHH Dược Liệu Quốc Tế': 'CTTNHHDLQT',
  'Công Ty TNHH Dược Mỹ Phẩm': 'CTTNHHDMP',
  'Công Ty TNHH Đầu Tư': 'CTTNHHĐT',
  ' Công Ty TNHH ĐT': 'CTTNHHĐT',
  'Công Ty TNHH Đầu Tư - DV - TM': 'CTTNHHĐTDVTM',
  'Công Ty TNHH Đầu Tư - Sản Xuất - TM - Dịch Vụ': 'CTTNHHĐTSXTMDV',
  'Công Ty TNHH ĐT - SX - TM&DV': 'CTTNHHĐTSXTMDV',
  'Công Ty TNHH ĐT KD TM': 'CTTNHHĐTKDTM',
  'Công ty TNHH ĐT SX & XNK': 'CTTNHHĐTSXXNK',
  'Công Ty TNHH Đầu Tư & Thương Mại Dược Phẩm': 'CTTNHHĐTTMDP',
  'Công Ty TNHH Đầu Tư Phát Triển': 'CTTNHHĐTPT',
  'Công Ty TNHH Đầu Tư Thương Mại': 'CTTNHHĐTTM',
  'Công Ty TNHH Đầu Tư TM Dịch Vụ': 'CTTNHHĐTTMDV',
  'Công Ty TNHH Đầu Tư TM DV': 'CTTNHHĐTTMDV',
  'Công Ty TNHH Đầu Tư TM&DV': 'CTTNHHĐTTMDV',
  'CÔNG TY TNHH ĐẦU TƯ TRANG THIẾT BỊ Y TẾ': 'CTTNHHĐTTTBYT',
  'Công Ty TNHH Một Thành Viên': 'CTTNHHMTV',
  'Công Ty TNHH MT': 'CTTNHHMTV',
  'Công ty TNHH MTV': 'CTTNHHMTV',
  'Công Ty TNHH MTV Dược Phẩm': 'CTTNHHMTVDP',
  'Công ty TNHH MTV Dược mỹ phẩm': 'CTTNHHMTVDMP',
  'Công ty TNHH MTV Đầu tư': 'CTTNHHMTVĐT',
  'Công Ty TNHH MTV Đầu Tư và Phát Triển': 'CTTNHHMTVĐTPT',
  'Công ty TNHH MTV Thương mại dịch vụ': 'CTTNHHMTVTMDV',
  'Công Ty TNHH MTV TM - DV Vụ': 'CTTNHHMTVTMDV',
  'Công Ty TNHH MTV TM DV Quốc Tế': 'Công Ty TNHH MTV TM DV Quốc Tế',
  'Công Ty TNHH MTV Thương Mai Dược Phẩm': 'CTTNHHMTVTMDP',
  'Công Ty TNHH MTV TM và XNK': 'CTTNHHMTVTMXNK',
  'Công Ty TNHH MTV TM&XNK': 'CTTNHHMTVTMXNK',
  'Công Ty TNHH Một Thành Viên Dược Phẩm': 'CTTNHHMTVDP',
  'Công Ty TNHH Mỹ Phẩm': 'CTTNHHMP',
  'Công Ty TNHH Nghiên Cứu Công Nghệ Vật Tư Y Tế': 'CTTNHHNCCNVTYT',
  'Công Ty TNHH Nuôi Trồng, Sản Xuất Và Chế Biến Dược Liệu': 'CTTNHHNTSXCBDL',
  'Công Ty TNHH NT, SX và CB Dược Liệu': 'CTTNHHNTSXCBDL',
  'Công Ty TNHH Phát Triển Thương Mại Và Dịch Vụ': 'CTTNHHPTTMDV',
  'Công Ty TNHH Phát Triển TM&DV': 'CTTNHHPTTMDV',
  'Công Ty TNHH Phát Triển XNK': 'CTTNHHSXTMDVXNK',
  'CÔNG TY TNHH SẢN XUẤT-THƯƠNG MẠI-XUẤT NHẬP KHẨU': 'CTTNHHSXTMXNK',
  'Công Ty TNHH SX-TM-XNK': 'CTTNHHSXTMXNK',
  'Công Ty TNHH Sản Xuất Và TM Dược Phẩm': 'CTTNHHSXTMDP',
  'Công ty TNHH Tập Đoàn Dược Phẩm': 'CTTNHHTĐDP',
  'Công Ty TNHH Thảo Dược': 'CTTNHHTD',
  'Công Ty TNHH Thiết Bị Vật Tư Y Tế': 'CTTNHHTBVTYT',
  'Công ty TNHH Thiết bị Nha khoa': 'CTTNHHTBNK',
  'Công Ty TNHH Thiết Bị Y Tế': 'CTTNHHTBYT',
  'Công Ty TNHH Thương Mại': 'CTTNHHTM',
  'Công ty TNHH Thương Mại & Dịch Vụ': 'CTTNHHTMDV',
  'Công Ty TNHH Thương Mại và Dịch Vụ': 'CTTNHHTMDV',
  'Công ty TNHH TM & DV': 'CTTNHHTMDV',
  'Công Ty TNHH TM Dịch Vụ': 'CTTNHHTMDV',
  'Công Ty TNHH TM DV Dược Phẩm': 'CTTNHHTMDVDP',
  'Công Ty TNHH TM DV Thực Phẩm': 'CTTNHHTMDVTP',
  'CÔNG TY TNHH THƯƠNG MẠI DỊCH VỤ KỸ THUẬT': 'CTTNHHTMKT',
  'Công Ty TNHH Thương Mại Dược Phẩm': 'CTTNHHTMDP',
  'Công Ty TNHH TM DP': 'CTTNHHTMDP',
  'Công ty TNHH TM Dược phẩm': 'CTTNHHTMDP',
  'Công ty TNHH TM và DP': 'CTTNHHTMDP',
  'Công Ty TNHH Thương Mại DP': 'CTTNHHTMDP',
  'Công ty TNHH thương mại quốc tế': 'CTTNHHTMQT',
  'Công Ty TNHH Thương Mại và Dược': 'CTTNHHTMD',
  'Công ty TNHH Thương Mại và Dược Phẩm': 'CTTNHHTMDP',
  'Công ty TNHH TM & DP': 'CTTNHHTMDP',
  'Công ty TNHH TM & XNK': 'CTTNHHTMXNK',
  'Công Ty TNHH Tư Vấn': 'CTTNHHTV',
  'Công Ty TNHH Văn Phòng Phẩm Và Thương Mại Dịch Vụ': 'CTTNHHVPPTMDV',
  'Công Ty TNHH Viện Nghiên Cứu': 'CTTNHHVNC',
  'Công Ty TNHH XNK TM Dịch Vụ': 'CTTNHHXNKTMDV',
  'Công Ty TNHH Y Dược': 'CTTNHHYD',
};

export const mapShortNamesLowercase = Object.keys(mapShortNames).reduce((x, y) => {
  const a = y.toLowerCase();
  return {
    ...x,
    [a]: mapShortNames[y],
  };
}, {});
