const ACTION = {
  VIEW: 'view',
  IMPRESSION: 'impression',
  CLICK: 'click',
  CLICK_ITEM: 'click_item',
  ADD_TO_CART: 'add_to_cart',
};

const EVENT_CATEGORY = {
  PRODUCT: 'Product',
  SUBSTITUTE_PRODUCT: 'Substitute Products',
  COMBO_SUGGESTION: 'Combo suggestion',
};

const PLATFORM = 'Web';

const TIME_TO_TRACK = [10, 30, 90, 300, 600];

export const SCREEN = {
  MY_ACCOUNT: 'My account',
  QUICK_ORDER: 'Quick order',
  PROMOTION: 'Promotion',
  CART: 'Cart',
  PRODUCT_LISTING: 'Product Listing',
  LIST_OF_SKUS: 'List of SKUs',
  SELLERS: 'Sellers',
  CHECKOUT: 'Checkout',
};

// https://thuocsi1-my.sharepoint.com/:x:/g/personal/thao_thuocsi_vn/Ectdykk4E59Pkyzi_lWivnMBapW58CEv3KtbOMUKhakyOg?e=hTiArp

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA;
export const GA_TRACKING_GLOBAL_ID = process.env.NEXT_PUBLIC_GA_GLOBAL;
export const GA_TRACKING_ID_V2 = process.env.NEXT_PUBLIC_GA_MOBILEV2;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url, user) => {
  if (GA_TRACKING_ID && window.gtagGA && !url?.endsWith('/loading')) {
    const data = {
      user_id: user?.customerID || '',
      client_id: user?.customerID || '',
      customer_id: user?.customerID || '',
      page_path: url,
      user_properties: {
        scope: user?.scope || '',
        level: user?.level || '',
        color: user?.color || '',
        customer_id: user?.customerID || '',
      },
    };
    window.gtagGA('config', GA_TRACKING_ID, data);
  }

  if (GA_TRACKING_GLOBAL_ID && window.gtagGlobal && !url?.endsWith('/loading')) {
    const data = {
      user_id: user?.customerID || '',
      client_id: user?.customerID || '',
      customer_id: user?.customerID || '',
      page_path: url,
      user_properties: {
        scope: user?.scope || '',
        level: user?.level || '',
        color: user?.color || '',
        customer_id: `vn-${user?.customerID || ''}`,
      },
    };
    window.gtagGlobal('config', GA_TRACKING_GLOBAL_ID, data);
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, params }) => {
  if (window && window.gtagGA) {
    window.gtagGA('event', action, params);
  }
};
export const eventV2 = ({ action, params }) => {
  if (window && window.gtagGAV2) {
    window.gtagGAV2('event', action, params);
  }
};

const sendPagePerformance = ({ path, url, timeGetAccount, timeExcuteDoWithServerSide, timeExcuteCallback }) => {
  if (timeGetAccount)
    event({ action: 'page_performance', params: { event_label: `timeGetAccount_${url}`, event_category: 'timeGetAccount', value: timeGetAccount } });
  if (timeExcuteCallback)
    event({
      action: 'page_performance',
      params: { event_label: `timeExcuteCallback_${url}`, event_category: 'timeExcuteCallback', value: timeExcuteCallback },
    });
  if (timeExcuteDoWithServerSide)
    event({
      action: 'page_performance',
      params: { event_label: `timeExcuteDoWithServerSide_${url}`, event_category: 'timeExcuteDoWithServerSide', value: timeExcuteDoWithServerSide },
    });
};

const showModalDiscovery = ({ sku }) =>
  event({ action: 'discovery_show_detail', params: { event_category: 'discovery', event_label: 'Show detail discovery', value: sku } });

const displayTopSearch = () => event({ action: 'display_topSearch', params: { event_category: 'TopSearch', event_label: 'Display Top Search' } });

const displayCountDownBar = () =>
  event({ action: 'display_countDownBar', params: { event_category: 'CountDownBar', event_label: 'Display CountDownBar' } });

const clickTopSearch = ({ url = '', hashtag = '' }) =>
  event({ action: 'click', params: { event_category: 'Homepage', value: url, event_label: `Click top search: ${hashtag}` } });

const clickTopSearchOutside = ({ url = '', hashtag = '' }) => eventSimple('click', 'Homepage', `Click topsearch outside <NAME: ${hashtag} | ${url}>`);

const displayTopSearchOutside = ({ url = '', hashtag = '' }) =>
  eventSimple(ACTION.IMPRESSION, 'Homepage', `Show topsearch outside <NAME: ${hashtag} | ${url}>`);

const convertCategory = (categories = []) => {
  const mapCategory = {};
  categories?.forEach((category, i) => {
    mapCategory[`item_category${i >= 1 ? i + 1 : ''}`] = category;
  });

  return mapCategory;
};

const convertProduct = (product) => {
  let itemBrand = '';
  const { sellerID, name, code } = product?.sellerInfo || {};
  if (sellerID && name && code) {
    // brand:  `${product?.sellerInfo?.name ? `${product?.sellerInfo?.name} - ` : ''}${product?.sellerCode || ''}`
    // chỉnh lại format theo chuẩn bên mkt gửi
    itemBrand = `${sellerID} - ${name} (${code})`;
  }

  return {
    item_id: product?.productId || '',
    item_name: product?.name || '',
    price: `${product?.displayPrice}` || '',
    value: product?.displayPrice || '',
    // tags: product?.tags || '',
    quantity: product?.quantity || 0,
    search_keyword: product?.searchKey || product?.search_keyword || '',
    item_brand: itemBrand,
    ...convertCategory(product?.categoryCodes || []),
  };
};

export const eventSimple = (action, event_category, event_label) => event({ action, params: { event_category, event_label } });
export const eventSimpleV2 = (action, event_category, event_label) => eventV2({ action, params: { event_category, event_label } });

const eventEcommerce = ({ action, products, params = {} }) => {
  // if (window.CONVERT_USD_TO_KHR) {
  //   // convert to USD
  //   if (params?.currency === 'KHR') params.currency = 'USD';

  //   if (products?.length > 0)
  //     products = products.map((product) => {
  //       product.displayPrice /= window.CONVERT_USD_TO_KHR;
  //       return product;
  //     });

  //   if (params?.value) params.value /= window.CONVERT_USD_TO_KHR;

  //   if (params?.shipping) params.shipping /= window.CONVERT_USD_TO_KHR;
  // }
  event({
    action,
    params: {
      ...params,
      items: products?.map((product) => convertProduct(product)) || [],
    },
  });
};

export const mapPathToCategory = (pathname = '') => {
  const path = pathname || window.location.pathname;

  if (path === '/user/wishlist' || path === '/productviewed') return SCREEN.MY_ACCOUNT;
  if (path === '/quick-order') return SCREEN.QUICK_ORDER;
  if (path === '/promotions') return SCREEN.PROMOTION;
  if (path === '/khuyenmai') return SCREEN.PROMOTION;
  if (path === '/cart') return SCREEN.CART;
  if (path === '/products') return SCREEN.PRODUCT_LISTING;
  if (path === '/checkout') return SCREEN.CHECKOUT;
  if (path.includes('seller') || path.includes('flagship-store') || path.includes('product-listing')) return SCREEN.SELLERS;
  return path;
};

const mapPathToLabel = {
  '/user/wishlist': 'Add To Cart mục sản phẩm quan tâm',
  '/productviewed': 'Add To Cart mục sản phẩm đã xem',
};

const clickMenubar = (item) => eventSimple('click', 'Homepage', `Click menubar <NAME: ${item.name} | URL: ${item.url}>`);
const clickSubMenubar = (item) =>
  eventSimple('click', 'Homepage', `Click submenu: <Menuname :${item.menuName}> | <Submenu_Name: ${item.label} | URL: ${item.url}>`);
const clickCountdownbar = (item) => eventSimple('click', 'Homepage', `Click countdownbar <TYPE: ${item.imageType} | URL: ${item.url}>`);
const clickBanner = (item) => eventSimple('click', 'Homepage', `Click banner <URL: ${item.link}>`);
const showBannerNewUI = (item) => {
  eventSimple(ACTION.IMPRESSION, 'Homepage', `Show Banner <Name:${item?.name} | ${item?.targetURL}>`);
};
const showNanobanner = (item) => {
  eventSimple(ACTION.IMPRESSION, 'Homepage', `Show Nanobanner <Name:${item?.name} | ${item?.targetURL}>`);
};
const showCampaignbox = (item) => {
  eventSimple(ACTION.IMPRESSION, 'Homepage', `Show Campaignbox <Name:${item?.title} | ${item?.url}>`);
};
const clickMiniBanner = (item) => {
  let imageIndex = item?.link?.imageIndex + 1;
  if (item.isMobile) {
    if (imageIndex === 5) imageIndex = 3;
    else if (imageIndex === 3) imageIndex = 5;
  }
  eventSimple('click', 'Homepage', `Click minibanner ${item.total}_${imageIndex} | URL: ${item?.link?.url}`);
};
const showMiniBanner = (item) => {
  let imageIndex = item?.link?.imageIndex + 1;
  if (item.isMobile) {
    if (imageIndex === 5) imageIndex = 3;
    else if (imageIndex === 3) imageIndex = 5;
  }
  eventSimple(ACTION.IMPRESSION, 'Homepage', `Show minibanner ${item.total}_${item?.link?.imageIndex + 1}  <URL: ${item.link.url}>`);
};

const showTopSearch = (hashtagTopSearch = []) => {
  if (hashtagTopSearch) {
    hashtagTopSearch.forEach(({ hashtag, url }) => {
      eventSimple(ACTION.IMPRESSION, 'Homepage', `Show topsearch <NAME: ${hashtag || ''} | ${url || ''}>`);
    });
  }
};
const showBanner = (item) => {
  if (document && !document.hidden) eventSimple(ACTION.IMPRESSION, 'Homepage', `Search most <URL: ${item.link}>`);
};

const showHashtagTopSearch = (suggestKeyword = '') => {
  if (suggestKeyword) {
    eventSimple(ACTION.IMPRESSION, 'Search', `Show most <NAME: ${suggestKeyword?.hashtag || ''}}>`);
  }
};
const showSubMenu = (item = {}) => {
  if (item) {
    eventSimple(ACTION.IMPRESSION, 'Search', `Show submenu: <Menuname :${item?.menuName}> | <Submenu_Name: ${item?.label} | URL: ${item?.url}>`);
  }
};
// homepage
const clickDrugGroup = (name) => eventSimple('click', 'Homepage', `Click Nhóm thuốc <${name}> `);
const clickCampaignbox = (name, url) => eventSimple('click', 'Homepage', `Click Campaignbox <Name:${name}> | ${url}`);
const clickNanobanner = (name, url) => eventSimple('click', 'Homepage', `Click Nanobanner <Name:${name}> | ${url}`);
const clickBannerNewUI = (item) => eventSimple('click', 'Homepage', `Click banner <Name:${item.name} | ${item.targetURL}>`);

const clickPromoCodeCondition = (code, isCart) =>
  eventSimple('click', `${isCart ? 'Promo-code-Cart' : 'Promo-code'}`, `Click Điều kiện voucher <NAME: ${code}>`);
// Click event category product
const clickTabInProduct = (tab) => eventSimple('click', EVENT_CATEGORY.PRODUCT, `Click tab <${tab}>`);
const clickViewShop = (shop) => eventSimple('click', EVENT_CATEGORY.PRODUCT, `Button "Xem store" ${shop}`);
const clickCategoryDrugsTitle = (ingredient) => eventSimple('click', EVENT_CATEGORY.PRODUCT, `Từng danh mục trong Nhóm thuốc: ${ingredient}`);
const clickCategoryManufacturerTitle = (manufacturer) =>
  eventSimple('click', EVENT_CATEGORY.PRODUCT, `Từng danh mục trong Nhà sản xuất: ${manufacturer}`);
const clickTagInProduct = (tag) => eventSimple('click', EVENT_CATEGORY.PRODUCT, `Click Tag ${tag}`);
const clickViewAllProductSameManufacturer = () => eventSimple('click', EVENT_CATEGORY.PRODUCT, 'Button "Xem tất cả" | Sản phẩm cùng nhà bán hàng');
const clickViewAllProductSameIngredient = () => eventSimple('click', EVENT_CATEGORY.PRODUCT, 'Button "Xem tất cả" | Cùng hoạt chất');
const clickViewAllThuocKeDonGiaTot = ({ currentUrl, redirect }) =>
  event({
    action: 'click_thuockedongiatot',
    params: {
      event_category: 'click_thuockedongiatot',
      event_label: 'Show all products ThuocKeDonGiaTot',
      page_location: currentUrl,
      page_referrer: redirect,
    },
  });

// Sellers event
const clickViewAllSellers = () => eventSimple('click', 'Sellers', 'Button  "xem tất cả"');
const clickSellerNameID = (sellerName, ID) => eventSimple('click', 'Sellers', `Click seller name: ${sellerName} - ID: ${ID}`);
const clickFlagshipStoreNameID = (storeName, ID) => eventSimple('click', 'Sellers', `Click flagship-store name: ${storeName} - ID: ${ID}`);

// Click event category order
const clickUsePromoCode = () => eventSimple('click', 'Order', 'Click mục "Dùng mã giảm giá" trong trang cart');
const clickUseDirectlyPromoCode = (code) => eventSimple('click', 'Order', `Click dùng ngay mã giảm giá ${code}`);

// Time tracking
const timeTracking = (label, eventCategory, time) => eventSimple(`timing_${time}`, eventCategory, label);

// Scroll depth
const scrollDepth = (label, eventCategory, scrollPercent) => eventSimple(`scrolldepth_${scrollPercent}_percent`, eventCategory, label);

// My Account
const clickTrackingOrder = () => eventSimple('click', 'My account', 'Click vào Trang "Tra cứu vận đơn"');
const clickRewardDetail = (name, id) => eventSimple('click', 'My account', `Click Reward Name: ${name} | ID: ${id}`);

// Promotion
const clickViewAllPromotion = (promotion) => eventSimple('click', 'Promotion', `Button "Xem tất cả" ${promotion}`);
const clickActivePromotionButton = () => eventSimple('click', 'Promotion', `ô khung giờ "Đang diễn ra"`);
const clickNextPromotionButton = () => eventSimple('click', 'Promotion', `ô khung giờ "Sắp diễn ra"`);
const clickShowAllUudaichihomnayBlock = () =>
  eventSimple('click', 'Khuyenmai', `Click Block <Ưu đãi chỉ hôm nay: ... | https://thuocsi.vn/products?tag=891Y >`);

// bien dong gia

const clickViewPageBienDongGia = ({ currentUrl, redirect }) =>
  event({
    action: 'click_biendonggia',
    params: {
      event_category: 'click_biendonggia',
      event_label: 'Go to page biendonggia',
      page_location: currentUrl,
      page_referrer: redirect,
    },
  });
// Search
const mapEventBySearchType = {
  1: 'Search',
  2: 'Search_ingredient',
  3: 'Search_seller',
  4: 'Search_drug',
};

const clickSearch = (type, keyword) => eventSimple(mapEventBySearchType[type], 'Search', `Keyword: "${keyword}"`);
const searchInQuickOrder = (keyword) => eventSimple('Search_Quick_order', 'Quick order', `Keyword: "${keyword}"`);

// Custom Ecommerce event with path
export const addToCartInPage = (product, path) => {
  eventEcommerce({
    action: ACTION.ADD_TO_CART,
    products: [product],
    params: {
      currency: 'VND',
      event_category: mapPathToCategory(path),
      event_label: mapPathToLabel[path] || 'Add To Cart',
    },
  });
};

export const viewItemInPage = ({ product, path }) => {
  eventEcommerce({
    action: ACTION.CLICK_ITEM,
    params: {
      content_type: 'Product',
      event_category: mapPathToCategory(path),
      event_label: `View Product Detail Has SKU:${product?.sku || ''}`,
    },
    products: [product],
  });
};

const toggleMobileV2 = (state) => eventSimple('click', 'Toggle Mobile V2', state ? 'V1 to V2' : 'V2 to V1');

const viewBlog = () => eventSimple('view_blog', 'Engagement', 'View Blog');
const viewCareer = () => eventSimple('view_career', 'Engagement', 'View Career');
const viewSeller = () => eventSimple('view_seller', 'Engagement', 'View Seller');
const popup = () => eventSimple('popup', 'Engagement', 'Click popup');
const callHotline = () => eventSimple('call', 'Engagement', 'Call Hotline');
const sendEmail = () => eventSimple('send_mail', 'Engagement', 'Send Mail');
const chat = () => eventSimple('chat', 'Engagement', 'Chat Facebook');
const fanpage = () => eventSimple('fanpage', 'Engagement', 'Fanpage');
const downloadIos = () => eventSimple('download_IOS', 'Engagement', 'Donwload App IOS');
const downloadAndroid = () => eventSimple('download_android', 'Engagement', 'Donwload App Android');
const downloadBanner = () => eventSimple('click', 'Homepage', 'Click Download App');
const referral = () => eventSimple('referral', 'Engagement', 'Referral SMS');

const register = () => eventSimple('register', 'User - Web', 'Register');
const registedCompleted = () => eventSimple('registedCompleted', 'User - Web', 'Registed Completed');

const sendFeedback = () => eventSimple('send_feedback', 'Order', 'Send Feedback');
const requestInvoice = () => eventSimple('request_invoice', 'Order', 'Request Invoice');

const viewPromotion = () => eventSimple('view_promotion', 'Promotion', 'View Discount Codes');
const selectPromotion = (promotions = []) =>
  event({ action: 'select_content', params: { event_category: 'Promotion', event_label: 'Select Discount Codes', promotions } });
// const removePromotion = () => eventSimple('remove_promotion', 'Promotion', 'Remove Discount Codes');
const exchangePoint = () => eventSimple('exchange_point', 'Promotion', 'Redeem Accumulation Points');

const reOrderShowPopup = () => eventSimple('re_order_click', 'ReOrder', 'Show popup re-order');
const reOrderClickOk = () => eventSimple('re_order_click_ok', 'ReOrder', 'Click ok re-order');
const reOrderClickCancel = () => eventSimple('re_order_click_cancel', 'ReOrder', 'Click cancel re-order');

const clickBtnChurnPopupWeb = () => eventSimple('click', 'click_button_churn_popup', 'click button churn popup Web');
const clickBtnChurnPopupMobileWeb = () => eventSimple('click', 'click_button_churn_popup', 'click button churn popup Mobile Web');

const voteV2 = (state) => eventSimpleV2('click', 'user_choose_option', state);
const surveyV2 = (state) => eventSimpleV2('click', 'user_click_survey', state);
const clickCashbackBar = () => eventSimple('click', 'cashback_bar_click');

// thuannc
// https://thuocsi1.sharepoint.com/:x:/s/MarketingTeam-Thuocsivn/EaYMD88U9jtLqH8UHbDXwbUBXFIXejaN3MJWOxU86-lwJg?e=Ev3bVq
const clickBtnSignInAction = () => eventSimple('click', 'Homepage', 'Click Sign In');
const clickBtnSignUpAction = () => eventSimple('click', 'Homepage', 'Click Sign Up');

export const addToCart = (product) => {
  eventEcommerce({
    action: ACTION.ADD_TO_CART,
    products: [product],
    params: {
      currency: 'VND',
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: 'Add To Cart',
    },
  });
};

const addToWishList = (product) => {
  eventEcommerce({
    action: 'add_to_wishlist',
    params: {
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: 'Add To Wishlist',
    },
    products: [product],
  });
};

const removeFromCart = (product) => {
  eventEcommerce({
    action: 'remove_from_cart',
    params: {
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: 'Remove from cart',
    },
    products: [product],
  });
};

const beginCheckout = (cartInfo) => {
  const { cartItems, redeemApplyResult = [] } = cartInfo || {};

  const voucherInfo = redeemApplyResult[0] || null;
  const { code = null, canUse = false } = voucherInfo || {};

  eventEcommerce({
    action: 'begin_checkout',
    params: {
      coupon: canUse && code,
      event_category: 'Order',
      event_label: 'Go To Checkout',
    },
    products: cartItems,
  });
};

const purchase = ({ cartId, cartItems = [], totalPrice = 0, deliveryMethodFee = 0, redeemApplyResult = [] }) => {
  const voucherInfo = redeemApplyResult[0] || null;
  const { code = null, canUse = false } = voucherInfo || {};

  eventEcommerce({
    action: 'purchase',
    params: {
      id: cartId,
      coupon: canUse && code,
      transaction_id: cartId,
      shipping: deliveryMethodFee || 0,
      value: totalPrice,
      currency: 'VND',
      event_category: 'Order',
      event_label: 'Action Checkout',
    },
    products: cartItems,
  });
};

const viewContent = ({ product }) => {
  eventEcommerce({
    action: ACTION.CLICK_ITEM,
    params: {
      content_type: 'Product',
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: `View Product Detail Has SKU:${product?.sku || ''}`,
    },
    products: [product],
  });
};

const selectItem = ({ product }) => {
  eventEcommerce({
    action: 'select_item',
    params: {
      content_type: 'Product',
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: `Select Item View Product Detail Has SKU:${product?.sku || ''}`,
    },
    products: [product],
  });
};

const viewContentDetail = ({ product }) => {
  eventEcommerce({
    action: 'view_item',
    params: {
      content_type: 'Product',
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: `View Product Detail Has SKU:${product?.sku || ''}`,
    },
    products: [product],
  });
};

const refund = ({ orderId, products, totalPrice, redeemCode = [] }) =>
  eventEcommerce({
    action: 'refund',
    params: {
      currency: 'VND',
      transaction_id: orderId,
      coupon: (redeemCode.length > 0 && redeemCode[0]) || null,
      id: orderId,
      value: totalPrice,
      event_category: 'Order',
      event_label: 'Cancel Order',
    },
    products,
  });

const checkoutClickSeeAllProducts = ({ user }) => {
  event({
    action: ACTION.CLICK,
    params: {
      event_category: 'Product list on Checkout page',
      event_label: 'Click button “See all” on “Product list” section',
      platform: PLATFORM,
      screen: SCREEN.CHECKOUT,
      customer_id: user?.customerID,
    },
  });
};

// gtag for substitute product list
const substituteSearchResult = ({ customerId, screen }) => {
  event({
    action: ACTION.IMPRESSION,
    params: {
      event_category: EVENT_CATEGORY.SUBSTITUTE_PRODUCT,
      event_label: 'Search result having substitute product impression',
      platform: PLATFORM,
      screen,
      customer_id: customerId,
    },
  });
};

const substituteHoverProductCard = ({ customerId, sku, sellerId, productId }) => {
  event({
    action: ACTION.IMPRESSION,
    params: {
      event_category: EVENT_CATEGORY.SUBSTITUTE_PRODUCT,
      event_label: 'Oos/suspended SKU impression',
      platform: PLATFORM,
      screen: mapPathToCategory(),
      customer_id: customerId,
      sku,
      seller_id: sellerId,
      product_id: productId,
    },
  });
};

const substituteClickDetail = ({ screen, customerId, sku, sellerId, productId }) => {
  event({
    action: ACTION.CLICK,
    params: {
      event_category: EVENT_CATEGORY.SUBSTITUTE_PRODUCT,
      event_label: 'Click button ‘detail’ at substitute product',
      platform: PLATFORM,
      screen,
      customer_id: customerId,
      sku,
      seller_id: sellerId,
      product_id: productId,
    },
  });
};

const substituteClickMore = ({ screen, customerId, sku, sellerId, productId }) =>
  event({
    action: ACTION.CLICK,
    params: {
      event_category: EVENT_CATEGORY.SUBSTITUTE_PRODUCT,
      event_label: 'Click button ‘more’ at substitute product',
      platform: PLATFORM,
      screen,
      customer_id: customerId,
      sku,
      seller_id: sellerId,
      product_id: productId,
    },
  });

const substituteClickAddToCart = ({ screen, customerId, sku, sellerId, productId }) => {
  event({
    action: ACTION.ADD_TO_CART,
    params: {
      event_category: EVENT_CATEGORY.SUBSTITUTE_PRODUCT,
      event_label: 'Add substitute product to cart',
      platform: PLATFORM,
      screen,
      customer_id: customerId,
      sku,
      seller_id: sellerId,
      product_id: productId,
    },
  });
};

const addSKUAtBlockToCart = ({ screen = '', block, user, skuProduct }) => {
  event({
    action: ACTION.ADD_TO_CART,
    params: {
      event_category: EVENT_CATEGORY.PRODUCT,
      event_label: `Add SKU at block to cart ${block.name} ${block.code}`,
      eventsource: 'recommendation-product-listing',
      platform: PLATFORM,
      screen: screen || mapPathToCategory(),
      customer_id: user?.customerID,
      block_bame: block.name,
      block_id: block.productBlockId,
      sku: skuProduct.skuCode,
      seller_id: skuProduct.sellerId,
      product_id: skuProduct.productId,
    },
  });
};

const impressionComboSuggestion = ({ user, skuProduct }) => {
  event({
    action: ACTION.IMPRESSION,
    params: {
      event_category: EVENT_CATEGORY.COMBO_SUGGESTION,
      event_label: 'Key SKU impression',
      platform: PLATFORM,
      screen: mapPathToCategory(),
      customer_id: user?.customerID,
      sku: skuProduct.skuCode,
      seller_id: skuProduct.sellerId,
      product_id: skuProduct.productId || skuProduct.id,
    },
  });
};

const clickComboSuggestionMore = ({ user, skuProduct, comboSKUCode }) => {
  event({
    action: ACTION.CLICK,
    params: {
      event_category: EVENT_CATEGORY.COMBO_SUGGESTION,
      event_label: 'Click button “Buy more” or full card at Combo suggestion',
      platform: PLATFORM,
      screen: mapPathToCategory(),
      customer_id: user?.customerID,
      combo: comboSKUCode,
      sku: skuProduct.skuCode,
      seller_id: skuProduct.sellerId,
      product_id: skuProduct.productId || skuProduct.id,
    },
  });
};

const addToCartComboSuggestion = ({ user, skuProduct, comboSKUCode }) => {
  event({
    action: ACTION.ADD_TO_CART,
    params: {
      event_category: EVENT_CATEGORY.COMBO_SUGGESTION,
      event_label: 'Add combo suggestion to cart on Combo suggestion detail page',
      platform: PLATFORM,
      screen: mapPathToCategory(),
      customer_id: user?.customerID,
      combo: comboSKUCode,
      sku: skuProduct.skuCode || skuProduct.sku,
      seller_id: skuProduct.sellerId || skuProduct.sellerID,
      product_id: skuProduct.productId || skuProduct.id,
    },
  });
};

export default {
  refund,
  pageview,
  event,
  addToCart,
  viewContent,
  eventEcommerce,
  removeFromCart,
  beginCheckout,
  timeTracking,
  purchase,
  addToWishList,
  clickRewardDetail,
  clickUsePromoCode,
  viewBlog,
  viewCareer,
  viewSeller,
  popup,
  callHotline,
  sendEmail,
  fanpage,
  downloadAndroid,
  clickViewAllPromotion,
  downloadIos,
  referral,
  chat,
  sendFeedback,
  requestInvoice,
  viewPromotion,
  exchangePoint,
  reOrderShowPopup,
  reOrderClickCancel,
  reOrderClickOk,
  selectPromotion,
  register,
  registedCompleted,
  displayTopSearch,
  clickTopSearch,
  displayCountDownBar,
  showModalDiscovery,
  clickMenubar,
  clickCountdownbar,
  clickBanner,
  clickTabInProduct,
  clickViewShop,
  clickCategoryDrugsTitle,
  clickCategoryManufacturerTitle,
  clickTrackingOrder,
  TIME_TO_TRACK,
  addToCartInPage,
  clickTagInProduct,
  clickViewAllProductSameManufacturer,
  clickViewAllProductSameIngredient,
  viewItemInPage,
  clickViewAllSellers,
  clickSellerNameID,
  clickFlagshipStoreNameID,
  scrollDepth,
  mapPathToCategory,
  clickActivePromotionButton,
  clickNextPromotionButton,
  clickUseDirectlyPromoCode,
  clickSearch,
  searchInQuickOrder,
  toggleMobileV2,
  showBanner,
  voteV2,
  surveyV2,
  sendPagePerformance,
  clickBtnChurnPopupWeb,
  clickBtnChurnPopupMobileWeb,
  clickMiniBanner,
  clickViewPageBienDongGia,
  clickViewAllThuocKeDonGiaTot,
  selectItem,
  viewContentDetail,
  showMiniBanner,
  clickBtnSignInAction,
  clickBtnSignUpAction,
  clickShowAllUudaichihomnayBlock,
  downloadBanner,
  clickCashbackBar,
  clickDrugGroup,
  clickCampaignbox,
  clickNanobanner,
  showBannerNewUI,
  showNanobanner,
  showCampaignbox,
  clickBannerNewUI,
  clickPromoCodeCondition,
  showTopSearch,
  clickSubMenubar,
  showHashtagTopSearch,
  showSubMenu,
  clickTopSearchOutside,
  displayTopSearchOutside,
  checkoutClickSeeAllProducts,
  substituteSearchResult,
  substituteHoverProductCard,
  substituteClickDetail,
  substituteClickAddToCart,
  substituteClickMore,
  addSKUAtBlockToCart,
  impressionComboSuggestion,
  clickComboSuggestionMore,
  addToCartComboSuggestion,
};
