import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'components/atoms/Modal';
import { PlatformType, PopupType } from 'constants/Enums/popup';
import { REMIND_OCR, REMIND_PAYMENT } from 'constants/PopupPriority';
import { useAuth } from 'context/Auth';
import { usePopup } from 'context/Popup';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getPopup } from 'services/WebService';
import styles from './styles.module.css';

/**
 * @typedef PopupData
 * @property {string} content The content to be displayed. E.g. "Vui lòng thanh toán đơn hàng <b>1538378</b> đặt ngày <b>09/11/2022</b> với số tiền <b>1.117.400đ</b> trước <b>13:37 ngày 09/11/2022</b>."
 * @property {string} ctaButton E.g. "Thanh toán ngay",
 * @property {string} ctaLink E.g. "/my-order/1538378",
 * @property {string} imageUrl E.g. "https://cdn-gcs.thuocsi.vn/thuocsi-live/images/202424e216bf8b705b430dc84a634507",
 * @property {string} title E.g. "Nhắc nhở thanh toán"
 */

const EXCLUDED_URLS = [
  // Add the URLs that should not show the popup here
  '/cart',
  '/change-payment',
  '/checkout',
  '/my-order',
  '/redirect',
  '/thankyou',
];

const RemindPaymentModal = () => {
  const { setActivePopup, activePopup, setCanShowNextPopup } = usePopup();
  const { isAuthenticated } = useAuth();
  const router = useRouter();

  /** @type {[PopupData, React.Dispatch<React.SetStateAction<PopupData>>]} */
  const [popupData, setPopupData] = useState({});

  useEffect(() => {
    if (!isAuthenticated) return;

    if (EXCLUDED_URLS.some((url) => router.pathname.startsWith(url))) {
      return;
    }

    (async () => {
      const res = await getPopup({
        type: PopupType.PAYMENT_REMIND_POPUP,
        platform: PlatformType.WEB,
      });

      if (res.status === 'OK') {
        setPopupData(res.data?.[0]);
        setActivePopup(REMIND_PAYMENT);
      } else {
        // Allow to show next popup when there is no popup data or error
        setActivePopup(REMIND_OCR);
      }
    })();
  }, [isAuthenticated]);

  function handleClose() {
    setCanShowNextPopup();
  }

  function handleRedirectToPayment() {
    router.push(popupData.ctaLink);
    setCanShowNextPopup();
  }

  return (
    <Modal open={activePopup === REMIND_PAYMENT} onClose={handleClose}>
      <div className={styles.confirm_modal_wrap}>
        <div className={styles.close} onClick={handleClose} role="button">
          Đóng
          <CloseIcon fontSize="small" />
        </div>

        <Typography className={styles.modal_title} data-test="remind-payment-modal-title">
          {popupData.title}
        </Typography>

        <Image src={popupData.imageUrl} alt="Nhắc nhở thanh toán" width={140} height={120} />

        <Typography className={styles.modal_content} data-test="remind-payment-modal-content">
          <span
            dangerouslySetInnerHTML={{
              __html: popupData.content,
            }}
          />
        </Typography>

        <Button className={styles.modal_ok_btn} onClick={handleRedirectToPayment} data-test="btn-remind-payment-modal-ok">
          {popupData.ctaButton}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(RemindPaymentModal);
