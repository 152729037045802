import DateTimeUtils from "utils/DateTimeUtils";
import { ENUM_NOTIFICATION_TAB, ENUM_NOTIFICATION_TAG, MapProps, NotificationProps, NotiTab } from "./interface";

const DAY = 86400;
const DOUBLE_DATE = 172800
const MONTH = 2629746;


export const getDateKey = (createdTime: string) => {
    let dateKey
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const diff = Math.round(((now as any) - +new Date(createdTime)) / 1000);
    const isSameDate = (now.getDate()) === (new Date(createdTime).getDate())
    switch (true) {
        case diff < DAY && isSameDate:
            dateKey = "Hôm nay";
            break;
        case diff < DOUBLE_DATE:
            if (Math.round(diff / DAY) < 2) {
                dateKey = "Hôm qua"
            } else {
                dateKey = DateTimeUtils.getFormattedDate(new Date(createdTime))
            }
            break;
        case diff < MONTH:
            dateKey = DateTimeUtils.getFormattedDate(new Date(createdTime))
            break;
        default:
            dateKey = "Quá 30 ngày";
            break;
    }

    return dateKey
}

export const AddKeyDateNotification = (notification: NotificationProps[], tab: ENUM_NOTIFICATION_TAB): {
    date: string,
    noti: NotificationProps[]
}[] => {
    if (!notification && (notification as NotificationProps[])?.length === 0) {
        return []
    }
    const newMapNotification = notification?.reduce<MapProps>((
        groupDate,
        noti
    ) => {
        if (!groupDate[getDateKey(noti?.createdTime)]) {
            // eslint-disable-next-line no-param-reassign
            groupDate[getDateKey(noti?.createdTime)] = [];
        }
        groupDate[getDateKey(noti?.createdTime)].push({
            ...noti,
        });

        return groupDate
    }, {}) || {}

    const groupArrays = Object.keys(newMapNotification).map((date) => ({
        date,
        noti: newMapNotification[date],
    }));

    return groupArrays.map((child) => ({ ...child })) || []
}

export const conditionIncludes = (tags: ENUM_NOTIFICATION_TAG[], type: NotiTab): boolean => {
    if (!type) return false

    switch (type) {
        case "important": {
            return tags?.includes(ENUM_NOTIFICATION_TAG.ACCOUNT) || tags?.includes(ENUM_NOTIFICATION_TAG.ORDER) || tags?.includes(ENUM_NOTIFICATION_TAG.TICKET)
        }
        case "product": {
            return tags?.includes(ENUM_NOTIFICATION_TAG.PRICE) || tags?.includes(ENUM_NOTIFICATION_TAG.PRODUCT)
        }
        default: {
            return tags?.includes(ENUM_NOTIFICATION_TAG.PROMOTION)
        }
    }
}

export const tagsInTab = (tab: ENUM_NOTIFICATION_TAB) => {
    switch (tab) {
        case ENUM_NOTIFICATION_TAB.IMPORTANT: {
            return [ENUM_NOTIFICATION_TAG.IMPORTANT].join(",")
        }
        case ENUM_NOTIFICATION_TAB.PRODUCT: {
            return [ENUM_NOTIFICATION_TAG.PRICE, ENUM_NOTIFICATION_TAG.PRODUCT].join(",")
        }
        case ENUM_NOTIFICATION_TAB.PROMOTION: {
            return [ENUM_NOTIFICATION_TAG.PROMOTION].join(",")
        }
        default: {
            return [].join(",")
        }
    }
}

export default {
    AddKeyDateNotification,
    getDateKey,
    conditionIncludes,
    tagsInTab
}