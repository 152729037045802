export const LOYALTY_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const ENUM_LOYALTY_POINT = {
  LUCKY_WHEEL: 'LUCKY_WHEEL',
  MISSION: 'MISSION',
  CHECKIN: 'CHECKIN',
};

export const LOYALTY_HISTORY_TYPE = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
};

export const AssetsLevelData = [
  {
    code: 'LEVEL_SILVER',
    rank: 'Bậc 1',
    background: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_silver_bg.png`,
    color: '#898989',
    icon: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_silver_icon.svg`,
  },
  {
    code: 'LEVEL_GOLD',
    rank: 'Bậc 2',
    background: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_gold_bg.png`,
    color: '#A95B3F',
    icon: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_gold_icon.svg`,
  },
  {
    code: 'LEVEL_PLATINUM',
    rank: 'Bậc 3',
    background: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_platinum_bg.png`,
    color: '#467584',
    icon: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_platinum_icon.svg`,
  },
  {
    code: 'LEVEL_DIAMOND',
    rank: 'Bậc 4',
    background: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_diamond_bg.png`,
    color: '#A87AC1',
    icon: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/userLoyalty/rank_diamond_icon.svg`,
  },
];

