import { DEFAULT_EXPANDED_STORAGE_KEY, SEARCH_RESULT_STORAGE_KEY, VISITED_URLS_STORAGE_KEY } from 'constants/data';

const clearStorage = () => {
  if (window && window.localStorage) {
    const preservedPrefixes = ['ins', 'sp', 'step', 'time-rating', 'finish', 'lastOpenRemindDate', 'dropDownMenuList'];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(localStorage)) {
      if (!preservedPrefixes.some((prefix) => key.startsWith(prefix))) {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem('logout', new Date());
    localStorage.setItem(VISITED_URLS_STORAGE_KEY, '[]');
    localStorage.removeItem(DEFAULT_EXPANDED_STORAGE_KEY);
    localStorage.removeItem(SEARCH_RESULT_STORAGE_KEY);
  }
};

export default {
  clearStorage,
};
