import Image from 'next/image';
import { useState } from 'react';
import { DOMAIN_CDN_IMAGE, NEW_MISSING_IMAGE, PROXY_IMAGE } from 'sysconfig';
import { getLinkCacheFromGG, getLinkFallbackCDN, getLinkFallbackCacheImage, getLinkImageStatic } from './CacheImageUtils';
import { getLinkCacheProxy, getLinkProxyCDN, getLinkProxyFile } from './ImageUtils';
import myLoader, { myLoaderCacheCDN, myLoaderCacheInstant, myLoaderCacheProxy } from './myLoader';

const STORE_IMAGE_DEFAULT = `${PROXY_IMAGE}/thuocsi-live/web/static/images/default_store.svg?size=origin`;

const ImageFallback = ({
  src,
  fallbackSrc,
  loading = 'eager',
  isUseLoader = true,
  isUseLoaderCache = false,
  isUseLoaderCacheProxy = false,
  isUseLoaderCdn = false,
  layout,
  ...rest
}) => {
  const props = { ...rest };

  const [imgSrc, setImgSrc] = useState(false);
  const [oldSrc, setOldSrc] = useState(src);
  if (oldSrc !== src) {
    if (isUseLoaderCache || isUseLoaderCacheProxy || isUseLoaderCdn) {
      isUseLoaderCache = false;
      isUseLoaderCacheProxy = false;
      isUseLoaderCdn = false;
    }
    setImgSrc(false);
    setOldSrc(src);
  }

  if (isUseLoader) {
    props.loader = myLoader;
  }

  if (isUseLoaderCache) {
    props.loader = myLoaderCacheInstant;
  }

  if (isUseLoaderCacheProxy) {
    props.loader = myLoaderCacheProxy;
  }

  if (isUseLoaderCdn) {
    props.loader = myLoaderCacheCDN;
  }

  // Remove warning `Image with src xxx and "layout='fill'" has unused properties assigned. Please remove "width" and "height".`
  if (layout === 'fill') {
    delete props.width;
    delete props.height;
  }
  props.layout = layout;

  return (
    <Image
      {...props}
      src={imgSrc ? fallbackSrc : getLinkProxyCDN(src)}
      onError={() => {
        setImgSrc(true);
      }}
      loading={loading}
    />
  );
};

export const ImageFallbackBanner = ({ src, width, q = 100, ...rest }) => (
  <ImageFallback
    src={getLinkCacheProxy({ url: src, w: width })}
    fallbackSrc={DOMAIN_CDN_IMAGE ? getLinkFallbackCDN({ url: src }) : getLinkCacheFromGG({ src })}
    isUseLoaderCacheProxy
    width={width}
    q={q}
    {...rest}
  />
);

export const ImageFallbackStatic = ({ src, q = 100, ...rest }) => (
  <ImageFallback src={getLinkImageStatic(src)} fallbackSrc={getLinkImageStatic(src)} isUseLoaderCacheProxy q={q} {...rest} />
);

export const ImageFallbackStoreImage = ({ src = STORE_IMAGE_DEFAULT, q = 100, ...rest }) => (
  <ImageFallback src={getLinkProxyFile(src) || STORE_IMAGE_DEFAULT} fallbackSrc={STORE_IMAGE_DEFAULT} isUseLoaderCacheProxy q={q} {...rest} />
);

export const ImageFallbackProductImage = ({
  src = NEW_MISSING_IMAGE,
  q = 100,
  quality = 100,
  loading = 'lazy',
  size = null,
  fallbackSrc = NEW_MISSING_IMAGE,
  ...rest
}) => (
  <ImageFallback
    src={(src && size ? `${src}${src?.includes('?') ? '&' : '?'}size=${size}` : src || NEW_MISSING_IMAGE) || NEW_MISSING_IMAGE}
    fallbackSrc={src ? getLinkFallbackCacheImage({ url: src }) : fallbackSrc || NEW_MISSING_IMAGE}
    isUseLoaderCacheProxy
    quality={quality}
    loading={loading}
    q={q}
    {...rest}
  />
);

export default ImageFallback;
