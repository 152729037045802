import { GET, PUT } from './Clients';

const NOTIFICATION_API_PREFIX = '/integration/notification/v1';

export const getNotify = async ({ ctx, limit = 20, page = 1, tags }) => {
  return GET({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification/me`,
    params: { limit, offset: (page - 1) * limit, tags },
  });
};

export const getTotalNotification = async ({ ctx, tags }) => {
  return GET({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification/me/counter`,
    params: { tags },
  });
};

export const markReadNotiByCode = async ({ ctx, code }) => {
  return PUT({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification`,
    body: { code },
  });
};

export const markReadAllNoti = async ({ ctx, tags }) =>
  PUT({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification/all`,
    body: { tags },
  });

export default {
  getNotify,
  getTotalNotification,
  markReadAllNoti,
  markReadNotiByCode,
};
