// Scan object, remove key with `undefined` value
function sanitizeObject(object, option = {}) {
  if (typeof object === 'undefined') return;

  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      if (sanitizeObject(object[i])) object.splice(i, 1);
    }
  } else {
    if (typeof object === 'object' && object != null) {
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i++) {
        if (sanitizeObject(object[keys[i]])) delete object[keys[i]];
        if (option.removeEmptyValues) if (object[keys[i]] === '') delete object[keys[i]];
        if (option.removeNullValues) if (object[keys[i]] === null) delete object[keys[i]];
      }
    }
  }
}

export function sanitize(object, option = {}) {
  const newObj = { ...object };
  sanitizeObject(newObj, option);
  return newObj;
}

export function isEmpty(item) {
  return Object.keys(item) === 0;
}

export function groupArrayOfObjects(list, key) {
  return list.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

