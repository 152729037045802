import { MAX_ORDER_IN_WEEK, MAX_SKU_IN_ORDER, NOTIFY_IN_TOP_BAR, PROVINCES_TURN_OFF_PAYMENT } from 'sysconfig';

export const getSettingInWeb = async ({ user }) => {
  // const totalOrderInWeek = await OrderService.getOrderCountInWeekStartDate({ ctx });
  const isDisplayNotiTopBar = PROVINCES_TURN_OFF_PAYMENT.indexOf('ALL') >= 0 || PROVINCES_TURN_OFF_PAYMENT?.indexOf(user?.provinceCode) >= 0 || false;

  return {
    MAX_ORDER_IN_WEEK,
    MAX_SKU_IN_ORDER,
    // totalOrderInWeek,
    isDisplayNotiTopBar,
    content: NOTIFY_IN_TOP_BAR,
    canOrderInWeek: MAX_ORDER_IN_WEEK,
    isDisablePayment: isDisplayNotiTopBar,
  };
};
