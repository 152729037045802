import { changeAlias } from 'utils/StringUtils';

// + link sản phẩm của trang khác
export const PreventSearchKeywords = [
  'thuốc xách tay',
  'hàng tuồn kho',
  'thuốc hết hạn',
  'thuốc phá giá',
  'thuốc phá thai',
  'hàng chợ',
  'ma tuý',
  'heroine',
  'súng',
  'lựu đạn',
  'bom',
  'mìn',
  'kiếm',
  'mác',
  'facebook',
  'lazada',
  'shopee',
  'tiki',
  'lê',
  'dao găm',
  'cung nỏ',
  'pháo',
  'thuốc lá',
  'xì gà',
  'thuốc trừ sâu',
  'amfibole',
  'amiăng',
  'hàng lậu',
  'độc hại',
  'độc tố',
  'cấm khai thác',
  'hoang dã',
  'quý hiếm',
  'tuyệt đối',
  'chính xác',
  'dứt điểm',
  'cam kết hiệu quả',
  'điều trị tận gốc',
  'tiệt trừ',
  'hàng đầu',
  'đầu tay',
  'chất lượng nhất',
  'tác dụng ngay',
  'giảm ngay',
  'cắt đứt',
  'giảm tức thì',
  'khỏi hẳn',
  'không lo',
  'phải dùng',
  'thuốc số 1',
  'thuốc số một',
  'tốt hơn tất cả',
  'hoàn toàn vô hại',
  'không tác dụng phụ',
  'thần dược',
  'hotline',
  'liên hệ qua',
  'số điện thoại',
  'địa chỉ',
  'số tài khoản',
  'chuyển tiền',
  'mua hàng qua đường link',
  'thuốc phá thai',
  'thuốc độc',
];

export const PreventSearchKeywordsAlias = PreventSearchKeywords?.filter((item) => item.split(' ').length > 1).map((item) => changeAlias(item));

