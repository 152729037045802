import { FRONTEND_APIS } from 'constants/APIUriV2';
import { IS_ENCRYPTED_PRICE } from 'sysconfig';
import { GET, POST } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

export const loadProductDetail = async ({ ctx, params }) =>
  GET({
    url: FRONTEND_APIS.PRODUCT_DETAIL,
    ctx,
    isBasic: true,
    params,
  });

export const loadProductsFuzzySearch = async ({ body, ctx, ...restProps }) => {
  const newBody = { ...body };

  if (newBody.filter?.categoryFilters?.length) {
    for (let i = 0; i < newBody.filter.categoryFilters.length; i += 1) {
      if (newBody.filter.categoryFilters[i].efficacyCodes?.length) {
        newBody.filter.categoryFilters[i].categoryCodes = [];
        newBody.filter.categoryFilters[i].code = '';
      }
    }
  }

  newBody.queryOption = {
    ...newBody.queryOption,
    quantityInCart: true,
    isReplacePriceAfterVoucher: true,
    isGetSKUReplace: true,
  };

  return POST({
    ctx,
    url: IS_ENCRYPTED_PRICE ? FRONTEND_APIS.FUZZY_SEARCH_ENCRYPTED : FRONTEND_APIS.FUZZY_SEARCH,
    body: newBody,
    ...restProps,
  });
};

export const getProductRecommendation = async ({ ctx, body }) => POST({ ctx, url: `${FRONTEND_APIS_PREFIX}/recommendation/product`, body });

export const getProductRecommendationLite = async ({ ctx, q = {}, offset = 0, limit = 40, queryOption = {} }) =>
  POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/recommendation/product-lite`,
    body: { q, offset, limit, queryOption },
  });

export const loadCart = async ({ ctx, params }) => {
  const newParams = {
    ...params,
    getVoucherAuto: params?.getVoucherAuto || false,
  };

  return GET({ ctx, url: FRONTEND_APIS.CART, params: newParams });
};

export const loadDataCart = async (params) => {
  const newParams = {
    ...params,
    getVoucherAuto: params?.getVoucherAuto || false,
  };
  return GET({ url: FRONTEND_APIS.CART, params: newParams });
};

export const loadDataCartEncrypted = async (params) => {
  const newParams = {
    ...params,
    getVoucherAuto: params?.getVoucherAuto || false,
  };
  return GET({ url: FRONTEND_APIS.CART_ENCRYPTED, params: newParams });
};

export const searchFuzzyLite = async ({ ctx, params, body }) => POST({ ctx, url: FRONTEND_APIS.FUZZY_SEARCH_LITE, params, body });

export const sellerSkipLogin = async () => GET({ url: FRONTEND_APIS.SELLER_SKIP_LOGIN, isBasic: true });

export const contactMe = async ({ body }) => POST({ url: FRONTEND_APIS.CONTACT_ME, body, isBasic: true });

export const getDoccenURL = async ({ ctx }) => GET({ ctx, url: `${FRONTEND_APIS_PREFIX}/customer/doccen` });

export const loadDataCartLite = async () => GET({ url: FRONTEND_APIS.CART_LITE });

export const loadDataCartLiteEncrypted = async () => GET({ url: FRONTEND_APIS.CART_LITE_ENCRYPTED });

export const getOrderDetail = async ({ ctx, params, body }) => GET({ ctx, url: FRONTEND_APIS.ORDER, params, body });

export const getYoutubeLivestream = async ({ ctx, params, body }) => GET({ ctx, url: FRONTEND_APIS.YOUTUBE_LIVESTREAM, params, body });

export const verifyTaxCode = async ({ taxCode, customerName, customerAddress }) =>
  POST({ url: FRONTEND_APIS.VERIFY_TAX_CODE, body: { taxCode, customerName, customerAddress } });

export const getTotalProductCategories = async ({ ctx, params, body }) => POST({ ctx, url: FRONTEND_APIS.CATEGORY_TOTAL_PRODUCT, params, body });

export const getCategories = async ({ ctx }) => GET({ ctx, url: FRONTEND_APIS.CATEGORIES });

export const getTopManufacturers = async ({ ctx, params, body }) => GET({ ctx, url: FRONTEND_APIS.TOP_MANUFACTURERS, params, body });

export const getPopup = async ({ ctx, params }) => GET({ ctx, url: `${FRONTEND_APIS_PREFIX}/popup`, params });

export const getRecentSearchTexts = async () => GET({ url: FRONTEND_APIS.RECENT_SEARCH_TEXTS });

export const getYearInReview = async ({ ctx, params, body }) => GET({ ctx, url: FRONTEND_APIS.YEAR_IN_REVIEW, params, body });
export const getMenuList = async ({ ctx, params, body }) => GET({ ctx, url: FRONTEND_APIS.MENU_LIST, params, body });

export const getMenuBar = async ({ ctx, params, body }) => GET({ ctx, url: FRONTEND_APIS.MENU_BAR, params, body });

export async function getScreenCartItemHelper() {
  return GET({ url: `${FRONTEND_APIS_PREFIX}/screen/cart/item-helper` });
}

export const getCommentsBySkuCode = async ({ isAuth = true, skuCode, sort, getTotal = false, offset, limit, excludeID = '', q = null }) => {
  const params = {
    skuCode,
    getTotal,
    sort,
    offset,
    limit,
    ...(q && { q: JSON.stringify(q) }),
    ...(excludeID && { excludeID }),
  };

  return GET({
    url: `${FRONTEND_APIS_PREFIX}/product/comment`,
    params,
    isAuth,
  });
};

export const createComment = async ({ isAuth = true, isBasic = false, content, targetCode, targetType = 'SKU' }) =>
  POST({
    url: `/marketplace/ticket/v1/comment`,
    body: {
      content,
      targetType,
      targetCode,
    },
    isAuth,
    isBasic,
  });

export const createCommentNotLogin = async ({
  isAuth = true,
  isBasic = false,
  fullname = '',
  phone = '',
  scope = '',
  provinceCode = '',
  content,
  targetCode,
  targetType = 'SKU',
}) =>
  POST({
    url: `/marketplace/ticket/v1/comment-not-login`,
    body: {
      fullname,
      phone,
      scope,
      provinceCode,
      content,
      targetType,
      targetCode,
    },
    isAuth,
    isBasic,
  });

export const createReply = async ({ isAuth = true, isBasic = false, content, parentCommentID }) =>
  POST({
    url: `/marketplace/ticket/v1/comment/reply`,
    body: {
      content,
      parentCommentID,
    },
    isAuth,
    isBasic,
  });

export const getReply = async ({ isAuth = true, parentCommentID, getTotal = false, offset, limit }) => {
  const params = {
    parentCommentID,
    getTotal,
    offset,
    limit,
  };

  return GET({
    url: `${FRONTEND_APIS_PREFIX}/product/comment/reply`,
    params,
    isAuth,
  });
};

export default {
  loadProductDetail,
  loadProductsFuzzySearch,
  loadCart,
  loadDataCart,
  searchFuzzyLite,
  sellerSkipLogin,
  contactMe,
  loadDataCartLite,
  getOrderDetail,
  getYoutubeLivestream,
  verifyTaxCode,
  getTotalProductCategories,
  getCategories,
  getPopup,
  getTopManufacturers,
  loadDataCartEncrypted,
  loadDataCartLiteEncrypted,
  getRecentSearchTexts,
  getYearInReview,
  getMenuList,
  getMenuBar,
};

