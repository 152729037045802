import MuiModal from '@material-ui/core/Modal';
import { memo } from 'react';
import styles from './styles.module.css';

function Modal({ className, onClose, children, disableBackdropClick, ...rest }) {
  return (
    <MuiModal
      className={`${className} ${styles.modalItem}`}
      onClose={(e, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') return;
        onClose(e);
      }}
      {...rest}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      {children}
    </MuiModal>
  );
}

export default memo(Modal);
