import {
  CACHE_PATH_IMAGE,
  DOMAIN_CDN,
  DOMAIN_CDN_IMAGE,
  DOMAIN_IMAGE_CACHE_LOADER,
  GG_IMAGE,
  NEW_MISSING_IMAGE,
  PROXY_IMAGE,
  STATIC_STORAGE,
  WEB_HOST,
} from 'sysconfig';

export const changeDomainStorage = (url) => url?.replace(GG_IMAGE, PROXY_IMAGE);
// export const changeDomainThuocsi = (url) => url.replace(WEB_HOST, DOMAIN_CDN); // deprecated 22Feb2023 - Dinh Tran

export const changeDomainGGToCDN = (url) => url.replace(GG_IMAGE, DOMAIN_CDN_IMAGE);
export const changeDomainProxyToCDN = (url) => url.replace(PROXY_IMAGE, DOMAIN_CDN_IMAGE);

export const changeDomainCDNToImageProxy = (url) => url.replace(`${DOMAIN_CDN_IMAGE}${CACHE_PATH_IMAGE}/`, 'https://');

export const changeDomainCacheToCDN = (url) =>
  `${url.replace(DOMAIN_IMAGE_CACHE_LOADER, DOMAIN_CDN_IMAGE)}${CACHE_PATH_IMAGE}/${PROXY_IMAGE.replace('https://', '')}`;

export const getLinkImageCache = ({ src, width, quality = 100 }) =>
  `${DOMAIN_IMAGE_CACHE_LOADER}/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;

export const getLinkCacheFromGG = ({ src, width, quality = 100 }) => getLinkImageCache({ src: changeDomainStorage(src), width, quality });

export const getLinkImageStatic = (url) =>
  (url?.startsWith(WEB_HOST) || url?.startsWith(DOMAIN_CDN) || url?.startsWith(PROXY_IMAGE) || url?.startsWith(DOMAIN_CDN_IMAGE)
    ? url
    : WEB_HOST + url
  ).replace(`${WEB_HOST}`, `${DOMAIN_CDN || WEB_HOST}`);

// deprecated 22Feb2023 - Dinh Tran
// export const getLinkImageStatic2 = (url) =>
//   (url?.startsWith(WEB_HOST) || url?.startsWith(PROXY_IMAGE) ? url : WEB_HOST + url).replace(`${WEB_HOST}`, `${DOMAIN_CDN || WEB_HOST}`);

export const getLinkFallbackCDN = ({ url = NEW_MISSING_IMAGE }) =>
  url?.startsWith(PROXY_IMAGE) ? changeDomainProxyToCDN(url) : url?.startsWith(GG_IMAGE) ? changeDomainStorage(url) : changeDomainCacheToCDN(url);

export const getLinkFallbackCacheImage = ({ url = NEW_MISSING_IMAGE }) => {
  if (typeof url !== 'string') {
    return NEW_MISSING_IMAGE;
  }

  return url?.startsWith(DOMAIN_CDN_IMAGE)
    ? changeDomainCDNToImageProxy(url)
    : url?.startsWith(GG_IMAGE)
    ? changeDomainGGToCDN(url)
    : changeDomainCacheToCDN(url);
};

export const getLinkImageStaticCache = ({ url, width, quality = 100, isCache = true }) =>
  url?.includes(DOMAIN_CDN) || url?.includes(WEB_HOST) || !isCache
    ? getLinkImageStatic(url)
    : getLinkImageCache({ src: getLinkImageStatic(url), width, quality });

export const getLinkStorageForFlagshipStore = (url, width = 1500, quality = 100) =>
  `${(STATIC_STORAGE + url).replace(GG_IMAGE, changeDomainCacheToCDN(DOMAIN_IMAGE_CACHE_LOADER))}?w=${width}&q=${quality || 75}`;

