import { HOME_ICON_CARET_UP } from 'constants/Images';
import { useEffect, useRef } from 'react';
import ImageFallback from './ImageFallback';

export default function ScrollToTop({ pathname }) {
  const divRef = useRef(null);
  const page = pathname.replace(/[/]/g, '');
  const SIZE_ICON = 40;
  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300 && !divRef.current?.classList?.contains('visible')) {
      divRef.current?.classList?.add('visible');
    }
    if (window.pageYOffset <= 300 && divRef.current?.classList?.contains('visible')) {
      divRef.current?.classList?.remove('visible');
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div ref={divRef} className={`back-to-top ${page}`} onClick={scrollToTop} role="presentation">
      <ImageFallback src={HOME_ICON_CARET_UP} width={SIZE_ICON} height={SIZE_ICON} alt="Quay về đầu trang" />
    </div>
  );
}
