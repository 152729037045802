import { GIFT_IMAGE } from 'constants/Images';
import { IS_ENCRYPTED_PRICE, SELLER_CODES_REQUIRE_GPP, TAG_NEW } from 'sysconfig';
import { formatCurrency } from 'utils/FormatNumber';
import { getProxyImageList } from 'utils/ImageUtils';
import { capitalize } from 'utils/StringUtils';
import { decryptAES, genkey } from 'utils/decode';
import { convertSlug, findDiscountPercent, getLimitOrder } from './ProductServiceV2';

const convertProductV3 = (item = {}) => {
  const {
    isAvailable = true,
    isWishList = false,
    product,
    sku,
    // deal = null,
    // nextPriceLevel = {},
    // campaign = null,
    skuDescription = {},
    limitPerDay = item?.consumedMaxQuantity?.maxQuantityPerDay || 0,
    quantityPurchasedToday = 0,
    consumedMaxQuantity = null, // web service
    // feature display product without login
    withoutLogin = false,
    isProductCard = false,
    quantity = 0, // cart quantity
  } = item || {};

  const deal = (item?.deal && JSON.parse(JSON.stringify(item?.deal || {}))) || null;
  const nextPriceLevel = JSON.parse(JSON.stringify(item?.nextPriceLevel || {}));
  const campaign = (item?.campaign && JSON.parse(JSON.stringify(item?.campaign))) || null;

  const {
    categoryCodes = null,
    name = '',
    origin = '',
    volume = null,
    unit = null,
    manufacturerCode = null,
    ingredients = [],
    ingredientCodes = [], // product detail sẽ trả ra thêm 1 field là ingredientCodes
    productID = '',
    documentFiles = [],
    weight = 0,
    height = 1,
    length = 1,
    width = 1,
    // retailInfo = {},// product detail sẽ trả ra thêm 1 field là retailInfo
  } = product || {};

  const retailInfo = JSON.parse(JSON.stringify(product?.retailInfo || {}));

  // requiredCertificates: [ 'GPP', 'BUSINESS_CERTIFICATE', 'BUSINESS_LICENSE' ],
  const {
    maxQuantityPerOrder = null,
    productCode = '',
    retailPriceType = '',
    retailPriceValue = 0,
    sellerCode = null,
    status = '',
    code = '',
    tags = [],
    slug = null,
    statusData = null,
    type,
    skus = [],
    skuTags = [],
    point = null,
    pointMultiplier = null,
    isActive, // true la co hien thi tren web, false la bi tat hien thi
    lotDates = [], // thong tin ve can date
    requiredCertificates = [],
    hasGift = false,
    vouchers = [],
    retailPriceValueEncrypt = '',
    skuLabels = [],
    discountPercentage: newDiscountPercentage = 0,
  } = sku || {};

  const { maxQuantity: maxQuantityDeal } = deal || {};

  const tagExpired = lotDates.find((ele) => ele?.isNearExpired === true) || {};

  const { expiredDate = '', isNearExpired = false } = tagExpired;

  // giá hợp đồng
  const isContractPrice = type === 'SKU_CONTRACT' || false;

  const isDeal = deal && deal?.status === 'ACTIVE' && new Date(deal?.startTime) <= new Date() ? true : null;

  const imageUrls = isDeal && deal?.imageUrls?.length > 0 ? deal?.imageUrls : item?.product?.imageUrls || [];
  const descriptionInfo = item?.description || {};
  if (skuDescription && skuDescription?.description) {
    descriptionInfo.description = skuDescription.description;
  }

  // const salePrice = deal?.price || retailPriceValue;
  // ưu tiên campaign

  const isCampaign = !!campaign || false;
  const isHappeningCampaign = (campaign && campaign?.isShow) || false;
  // anpham: push status into tags
  const newTags = (status !== 'NORMAL' && status !== 'NEAR_EXPIRATION' && [...tags, status]) || tags;
  if (isNearExpired) newTags.push('NEAR_EXPIRATION');
  const isNew = tags?.indexOf(TAG_NEW) >= 0;
  // logic check sản phẩm này có dc hiển thị hay ko ở đây
  let errorMessageProduct = null;
  let customMessageproduct = null;
  let requireGPPMessage = null;

  if (SELLER_CODES_REQUIRE_GPP?.length > 0 && sellerCode && SELLER_CODES_REQUIRE_GPP.indexOf(sellerCode) >= 0) {
    requireGPPMessage = 'Yêu cầu cung cấp GPP và giấy phép kinh doanh';
  }
  if (item.errorCode === 'REQUIRED_CERTIFICATE') {
    requireGPPMessage = item.errorMessage || null;
  }
  // off isAvailable
  // feature display product without login
  if (!withoutLogin) {
    if (!retailPriceValue || retailPriceValue === 0 || status === 'STOP_SELLING') {
      errorMessageProduct = 'Đang cập nhật giá';
      if (IS_ENCRYPTED_PRICE && retailPriceValueEncrypt) {
        errorMessageProduct = '';
      }
    }
    if (isAvailable !== null && !isAvailable) {
      errorMessageProduct = 'Sản phẩm chưa hỗ trợ giao vùng này';
    }
    if (!isActive) errorMessageProduct = 'Sản phẩm tạm ngưng bán';
    switch (status) {
      case 'SUSPENDED':
        errorMessageProduct = 'Sản phẩm đã ngưng bán';
        break;
      case 'STOP_PRODUCING':
        errorMessageProduct = 'Sản phẩm ngưng sản xuất';
        break;
      case 'OUT_OF_STOCK':
        errorMessageProduct = 'Sản phẩm tạm hết hàng';
        break;
      default:
    }
  }
  const dealPrice = (isDeal && deal && deal?.price) ?? null;
  const imagesProxy = getProxyImageList(imageUrls);

  const isGift = status === 'GIFT';

  // variable template
  // update formatedCurrency
  const displayPrice = dealPrice ?? retailPriceValue;

  // product type
  let productType = 'NORMAL';
  let typeItem = 'NORMAL';
  const hidenPrice = null;
  let maxQuantity = maxQuantityPerOrder;
  const maxQuantityPerOrderItem = maxQuantityPerOrder;

  // percentage of quantity sold and sold out condition
  let percentDealSold;
  // available products to be able to buy in campaign
  let availableProducts;
  let discountPercentage;
  const messageLimitOrder = getLimitOrder(limitPerDay, maxQuantityPerOrderItem, unit);

  const priceObject = {
    salePrice: retailPriceValue,
    dealPrice,
    displayPrice,
  };

  /// ============= DECODE PRICE =============
  if (IS_ENCRYPTED_PRICE && retailPriceValueEncrypt) {
    // do action
    const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);

    const encodeRetailPriceValue = decryptAES({ key: keygen, value: retailPriceValueEncrypt || '' });
    priceObject.displayPrice = priceObject.dealPrice ?? encodeRetailPriceValue;

    if (deal?.priceEncrypt) {
      const encodeDealPrice = decryptAES({ key: keygen, value: deal?.priceEncrypt || '' });
      priceObject.dealPrice = encodeDealPrice;
      priceObject.displayPrice = encodeDealPrice ?? encodeRetailPriceValue;
      deal.price = encodeDealPrice;
    }

    priceObject.salePrice = encodeRetailPriceValue;

    // if have campaign
    if (isHappeningCampaign) {
      const retailPriceCampaign = decryptAES({ key: keygen, value: campaign?.retailPriceValueEncrypt || '' });
      campaign.retailPriceValue = retailPriceCampaign;
    }

    if (retailInfo.retailRefPriceEncrypt) {
      retailInfo.retailRefPrice = decryptAES({ key: keygen, value: retailInfo.retailRefPriceEncrypt || 0 });
    }

    if (nextPriceLevel && nextPriceLevel.priceEncrypt) {
      nextPriceLevel.price = decryptAES({ key: keygen, value: nextPriceLevel.priceEncrypt || 0 });
    }
  }

  if (!errorMessageProduct && (!priceObject.salePrice || priceObject.salePrice === 0 || status === 'STOP_SELLING')) {
    errorMessageProduct = 'Đang cập nhật giá';
  }
  // ============= END DECODE PRICE =============

  // nếu có campaign
  if (isHappeningCampaign) {
    const {
      campaign: campaignInfo,
      maxQuantityPerOrder: maxQuantityPerOrderCampaign = maxQuantityPerOrder,
      quantity: maxQuantityCampaign,
      retailPriceValue: retailPriceCampaign,
      soldQuantity = 0,
    } = campaign;

    percentDealSold = (soldQuantity / campaign?.quantity) * 100;

    if (percentDealSold < 100) {
      newTags.push('DEAL');
      discountPercentage = findDiscountPercent(priceObject?.salePrice, retailPriceCampaign);
      availableProducts = Math.min(maxQuantityCampaign - soldQuantity, maxQuantityPerOrderCampaign);
      if (campaignInfo?.campaignType === 'NORMAL') {
        productType = 'MEGA_DAY';
      } else if (campaignInfo?.campaignType === 'FLASH_SALE') {
        productType = 'FLASH_SALE';
        newTags.push(productType);
      }
      // price for campaign
      typeItem = 'CAMPAIGN';
      priceObject.displayPrice = retailPriceCampaign;
      maxQuantity = maxQuantityCampaign;
    } else {
      productType = 'NORMAL';
      customMessageproduct = `Bạn đã bỏ lỡ giá khuyến mãi ${formatCurrency(retailPriceCampaign)}`;
    }
  } else if (isDeal) {
    productType = 'DEAL';
    typeItem = 'DEAL';
    // MIN
    maxQuantity = Math.min(maxQuantityDeal, maxQuantityPerOrder);
    discountPercentage = findDiscountPercent(priceObject?.salePrice, priceObject?.dealPrice);
    newTags.push(productType);
    availableProducts = deal?.maxQuantity - deal?.quantity || 0;
    percentDealSold = ((deal?.quantity || 0) / deal?.maxQuantity) * 100;
  } else {
    productType = 'NORMAL';
    // availableProducts = limitPerDay;
  }

  priceObject.salePriceFormated = formatCurrency(priceObject.salePrice) || '';
  priceObject.dealPriceFormated = formatCurrency(priceObject.encodeDealPrice) || '';
  priceObject.displayPriceFormated = formatCurrency(priceObject.displayPrice) || '';

  // end
  // @deprecase
  // tag giao hang 7 days
  // if (tags?.indexOf(TAG_7DAYS) >= 0) {
  //   newTags.push('TAG_7DAYS');
  // }

  if (isProductCard) {
    return {
      consumedMaxQuantity,
      isNew,
      isAvailable,
      isWishList,
      defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
      originalImages: getProxyImageList(item?.product?.imageUrls || []),
      maxQuantity,
      maxQuantityPerOrder: maxQuantityPerOrderItem,
      maxQuantityPerDay: limitPerDay,
      limitPerDay,
      quantityPurchasedToday,
      // price
      // salePrice: retailPriceValue,
      // salePriceFormated: formatCurrency(retailPriceValue) || '',
      // dealPrice,
      // dealPriceFormated: formatCurrency(dealPrice) || '',
      // displayPrice,
      // displayPriceFormated: formatCurrency(displayPrice) || '',
      ...priceObject,
      hidenPrice,
      // sku
      sku: code,
      // combo
      skus,
      skuId: productCode,
      // code
      slug: convertSlug(slug),
      status,
      // deal
      // nếu tồn tại campaing thì sẽ ko tồn tại deal- thuannc 06Jan2022
      deal: isCampaign ? null : deal,
      isDeal,
      // seller
      sellerCode,
      seller: withoutLogin
        ? {}
        : {
            code: sellerCode,
            slug, // not yet
          },
      // info
      // DONE: name deal thuannc
      name: (isDeal && deal && deal?.name) || name,
      nameNormal: name,
      productId: productID, // for insider
      unit,
      volume: capitalize(volume),
      statusData,
      productSkuType: type,
      tags: withoutLogin ? [] : newTags || null,
      type: typeItem || null,
      productType,
      isGift,
      errorMessageProduct,
      customMessageproduct,
      requiredCertificates,
      skuTags,
      // campaign
      isCampaign,
      campaign,
      discountPercent: newDiscountPercentage || discountPercentage || 0,
      isHappeningCampaign,
      availableProducts: availableProducts || 0,

      // percentage discount for progress
      percentDealSold: percentDealSold || 0,
      isContractPrice, // giá hợp đồng
      // plus points
      point,
      pointMultiplier,
      messageLimitOrder,
      isActive,
      expiredDate,
      quantity, // cart quantity
    };
  }

  const productInfo = {
    consumedMaxQuantity,
    isNew,
    isAvailable,
    imageUrls,
    isWishList,
    imagesProxy: isGift ? imagesProxy || [GIFT_IMAGE] : imagesProxy,
    defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
    originalImages: getProxyImageList(item?.product?.imageUrls || []),
    maxQuantity,
    maxQuantityPerOrder: maxQuantityPerOrderItem,
    maxQuantityPerDay: limitPerDay,
    limitPerDay,
    quantityPurchasedToday,

    // price
    // salePrice: retailPriceValue,
    // salePriceFormated: formatCurrency(retailPriceValue) || '',
    // dealPrice,
    // dealPriceFormated: formatCurrency(dealPrice) || '',
    nextPrice: isDeal || isCampaign ? 0 : nextPriceLevel?.price || 0,
    nextLevel: nextPriceLevel?.code || '',
    // displayPrice,
    // displayPriceFormated: formatCurrency(displayPrice) || '', // không sài giá âm ví dụ: -410000đ
    ...priceObject,
    hidenPrice,
    // sku
    sku: code,
    skuTags,
    // combo
    skus,
    skuId: productCode,
    kind: retailPriceType,
    // code
    slug: convertSlug(slug),
    status,
    ingredients,
    ingredientCodes,
    manufacturerCode,
    categoryCodes,

    // type

    // deal
    // nếu tồn tại campaing thì sẽ ko tồn tại deal- thuannc 06Jan2022
    deal: isCampaign ? null : deal,
    isDeal,
    // seller
    sellerCode,
    seller: withoutLogin
      ? {}
      : {
          code: sellerCode,
          slug, // not yet
        },
    // info
    // DONE: name deal thuannc
    name: (isDeal && deal && deal?.name) || name,
    nameNormal: name,
    productId: productID, // for insider
    unit,
    origin,
    description: descriptionInfo,
    volume: capitalize(volume),
    weight,
    productVolume: width * height * length,
    statusData,
    productSkuType: type || null,
    tags: withoutLogin ? [] : newTags || null,
    skuLabels,
    skuTags,
    type: typeItem || null,
    productType,
    isGift,
    errorMessageProduct,
    customMessageproduct,
    requireGPPMessage,
    requiredCertificates,
    documentFiles,

    // campaign
    isCampaign,
    campaign,
    discountPercent: newDiscountPercentage || discountPercentage || 0,
    isHappeningCampaign,
    availableProducts: availableProducts || 0,

    // percentage discount for progress
    percentDealSold: percentDealSold || 0,
    isContractPrice, // giá hợp đồng
    // plus points
    point,
    pointMultiplier,
    messageLimitOrder,
    isActive,
    expiredDate,
    isTagGift: hasGift,
    vouchers,
    quantity, // cart quantity
  };

  return productInfo;
};

const mapDataProductV2 = ({ isAvailable = false, product }) => {
  try {
    const time = new Date().getTime();
    let data = product;
    if (isAvailable) {
      data = data.filter((item) => item.isAvailable);
    }

    const productV2 =
      data?.map?.((item) => {
        const info = convertProductV3({ ...item });
        return {
          ...info,
        };
      }) || [];
    productV2.timeMapData = +new Date() - time;
    return productV2;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default mapDataProductV2;
