import { POST } from './Clients';

const MONITOR_API_PREFIX = '/m/c/v1';

export const collectEvent = (event, metadata, numericMetadata = null) => {
  const body = {
    event,
    metadata,
    numericMetadata,
  };

  body.clientUserTime = new Date();

  POST({ url: `${MONITOR_API_PREFIX}/e`, body, timeout: null, priority: 'low' });
};

export const collectEvents = (events) =>
  POST({ url: `${MONITOR_API_PREFIX}/es`, body: { datas: events, clientUserTime: new Date() }, timeout: null, priority: 'low' });
