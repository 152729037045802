import { getFirst, isValid } from 'clients/Clients';
import NotifyClient from 'clients/NotifyClient';

export const getTotalNotification = async ({ ctx, tags }) => {
  const res = await NotifyClient.getTotalNotification({ ctx, tags });
  if (!isValid(res)) {
    return {};
  }
  return getFirst(res);
};

export const getNotifications = async ({ ctx, limit = 20, page = 1, tags }) => {
  const res = await NotifyClient.getNotify({ ctx, limit, page, tags });
  return res;
};

export const markReadAll = async ({ ctx, tags }) => NotifyClient.markReadAllNoti({ ctx, tags });

export const markReadByCode = async ({ ctx, code }) => NotifyClient.markReadNotiByCode({ ctx, code });

export const getClientNotifications = async ({ limit = 20, page = 1, tags }) => {
  const res = await NotifyClient.getNotify({ limit, page, tags })
  return res
}

export default {
  getTotalNotification,
  getNotifications,
  markReadAll,
  markReadByCode,
  getClientNotifications
};
