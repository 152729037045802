import { GET, POST, PUT, getSessionToken } from './Clients';

const ACCOUNT_API_PREFIX = '/core/account/v1';
const CUSTOMER_API_PREFIX = '/marketplace/customer/v1';

export const getUserWithContext = async (ctx, retry = 0) => {
  const ss = getSessionToken(ctx);
  if (!ss) {
    return { isAuthenticated: false };
  }
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me`,
    retry,
  });
};

export const loginInternal = async ({ ctx, body }) =>
  POST({
    ctx,
    url: `${ACCOUNT_API_PREFIX}/authentication`,
    body: { ...body, type: 'EMPLOYEE' },
    isAuth: true,
  });

export const login = async (body) =>
  POST({
    url: `${CUSTOMER_API_PREFIX}/authentication`,
    body,
    isAuth: false,
  });

export const logout = async () => PUT({ url: `${ACCOUNT_API_PREFIX}/logout` });

export const passwordRecovery = async (body) =>
  POST({
    url: `${ACCOUNT_API_PREFIX}/password-recovery`,
    body,
    isBasic: true,
    isAuth: false,
  });

export const passwordUpdate = async (body) =>
  PUT({
    url: `${ACCOUNT_API_PREFIX}/password-recovery`,
    body,
    isBasic: true,
    isAuth: false,
  });

export const loginLocal = async (body) =>
  POST({
    url: '/login',
    body,
    page: true,
    mock: true,
    isAuth: false,
  });

// { username password }
export const signUp = async (body) =>
  POST({
    url: `${CUSTOMER_API_PREFIX}/register`,
    body,
    isAuth: false,
  });

export const registerGuest = async (body) =>
  POST({
    url: `${CUSTOMER_API_PREFIX}/guest`,
    body,
    isBasic: true,
  });

export const registerV1 = async ({ t }) =>
  GET({
    url: `${CUSTOMER_API_PREFIX}/register`,
    params: { t },
    isAuth: false,
    isBasic: true,
  });

export const getAccountInfo = async ({ ctx }) =>
  GET({
    ctx,
    url: `${ACCOUNT_API_PREFIX}/me`,
  });

export const getUser = async (retry = 0) => {
  const result = await GET({
    url: `${CUSTOMER_API_PREFIX}/me`,
    params: { getCustomer: true },
    retry,
  });
  return result;
};

export const newPassword = async (body) =>
  PUT({
    url: `${ACCOUNT_API_PREFIX}/me/password`,
    body,
    isAuth: true,
  });

export const createPassword = async (body) =>
  POST({
    url: `${ACCOUNT_API_PREFIX}/account/create-password`,
    body,
    isAuth: true,
  });

export const CreatePasswordOtp = async (body) =>
  PUT({
    url: `${ACCOUNT_API_PREFIX}/account/create-password`,
    body,
    isAuth: true,
  });

export const getAccount = async (retry = 0) => {
  const result = await GET({
    url: `${CUSTOMER_API_PREFIX}/me`,
    params: { getAccount: true, getFromMainDB: true },
    retry,
  });
  return result;
};

export default {
  login,
  getUser,
  signUp,
  registerGuest,
  getUserWithContext,
  loginLocal,
  getAccountInfo,
  passwordRecovery,
  passwordUpdate,
  registerV1,
  newPassword,
  logout,
  loginInternal,
  createPassword,
  CreatePasswordOtp,
  getAccount,
};

