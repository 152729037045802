import { collectEvent } from 'clients/MonitorClient';
import { HTTP_STATUS } from 'constants/Enums/http';
import { SEARCH_RESULT_STORAGE_KEY } from 'constants/data';
import { MONITORING_COLLECTOR_TYPE } from 'utils/MonitorUtils';
import create from 'zustand';
import { persist } from 'zustand/middleware';

const MAX_TIME_SEARCH = 60000;
const DONE_TYPING_INTERVAL = 5000;
let typingTimer;

const useSearchResult = create(
  persist(
    (set, get) => ({
      searchResult: {},
      getSearchResult: () => {
        if (get()?.searchResult) {
          return get()?.searchResult;
        }
        return {};
      },
      storeSearchResult: (searchRes, content) => {
        const dataSearch = get()?.searchResult || {};
        const searchTime = new Date().getTime();

        Object.keys(dataSearch).forEach((key) => {
          if (Math.abs(searchTime - (dataSearch[key].sendEventSearchTime || 0)) > MAX_TIME_SEARCH) delete dataSearch[key];
        });

        dataSearch[content] = { ...(dataSearch[content] || {}), ...searchRes, content, searchTime };
        const productDataSearchMap = {};

        dataSearch[content].data?.forEach((prd) => {
          productDataSearchMap[prd.product?.productID || prd.productId] = prd;
        });

        dataSearch[content].data = productDataSearchMap;

        set({ ...get(), searchResult: dataSearch });
      },
      sendSearchEv: async (keyword, status, total) => {
        get().clearTimeoutSearchEvent();
        return collectEvent(
          MONITORING_COLLECTOR_TYPE.SEARCH,
          {
            event: MONITORING_COLLECTOR_TYPE.SEARCH,
            content: keyword || '',
            status: status || 'OK',
            host: window?.location?.host || '',
          },
          {
            total: total || 0,
          },
        );
      },
      sendSearchEvent: async (searchKeyword) => {
        try {
          const searchResult = get()?.searchResult || {};
          const { content = '', status = '', total = 0, searchTime, sendEventSearchTime } = searchResult?.[searchKeyword];

          if (status && content?.trim()?.length > 0 && Math.abs(searchTime - (sendEventSearchTime || 0)) > MAX_TIME_SEARCH) {
            // TODO: 26May2023 add customer tag & time response
            const metadata = {
              event: MONITORING_COLLECTOR_TYPE.SEARCH,
              content: content || '',
              status: status || '',
              host: window?.location?.host || '',
            };

            // TODO: add time resposne to metadata
            const numericMetadata = { total: total || 0 };

            const res = await collectEvent(MONITORING_COLLECTOR_TYPE.SEARCH, metadata, numericMetadata);

            if (res?.status === HTTP_STATUS.Ok) {
              searchResult[searchKeyword].sendEventSearchTime = new Date().getTime();
              set({ ...get(), searchResult });
            }

            return res;
          }
        } catch (err) {
          console.error(err);
        }

        return null;
      },
      clearTimeoutSearchEvent: () => {
        if (typingTimer) {
          clearTimeout(typingTimer);
        }
      },
      handleSendEvSearchTimeout: async (searchKeyword = '', status, total) => {
        get().clearTimeoutSearchEvent();
        typingTimer = setTimeout(() => {
          if (searchKeyword) {
            get().sendSearchEv(searchKeyword, status, total);
          }
        }, DONE_TYPING_INTERVAL);
      },
      handleSendEventSearchTimeout: async ({ resData = null, searchKeyword = '', page = 0 }) => {
        if (resData) get().storeSearchResult(resData, searchKeyword || '');
        // Gửi event search sau khi dừng search 5s
        get().clearTimeoutSearchEvent();

        typingTimer = setTimeout(() => {
          if (searchKeyword && page <= 1) {
            get().sendSearchEvent(searchKeyword);
          }
        }, DONE_TYPING_INTERVAL);
        // Gửi event search sau khi dừng search 5s
      },
      reset: () => {
        useSearchResult.persist.clearStorage();
      },
    }),
    {
      name: SEARCH_RESULT_STORAGE_KEY,
    },
  ),
);

export default useSearchResult;

