import { HTTP_STATUS } from 'constants/Enums/http';
import { isEmpty } from 'utils/ValidateUtils';
import { GET, GET_ALL } from './Clients';

const CORE_MASTER_DATA_PREFIX = '/core/master-data/v1';

const getProvinces = async (ctx) =>
  GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/provinces/list`,
    isBasic: true,
    isAuth: false,
  });

const getRegions = async (ctx) =>
  GET_ALL({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/region/list`,
    isBasic: true,
    isAuth: false,
    cache: true,
  });

const getDistrictsByProvince = async (provinceCode, isAuth, isBasic) =>
  GET({
    url: `${CORE_MASTER_DATA_PREFIX}/districts`,
    params: { provinceCode },
    isAuth,
    isBasic,
  });

const getWardsByDistrict = async (districtCode = '', isAuth, isBasic) => {
  if (isEmpty(districtCode)) {
    return { status: HTTP_STATUS.invalid, msg: 'DistrictCode not found' };
  }
  return GET({
    url: `${CORE_MASTER_DATA_PREFIX}/administratives/list`,
    params: { districtCode },
    isAuth,
    isBasic,
  });
};

const getWardByCode = async (ctx, wardCode) => {
  GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/ward/list`,
    params: { wardCode },
  });
};

const getDistrictByCode = async (ctx, code) =>
  GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/district`,
    params: { code },
  });

const getProvinceByCode = async (ctx, code) =>
  GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/province`,
    params: { code },
  });

const getAllCountriesMock = async ({ ctx }) =>
  GET({
    ctx,
    url: '/web/master-data/country',
    mock: true,
    isAuth: false,
  });

const getAllProvinceMock = async ({ ctx }) =>
  GET({
    ctx,
    url: '/web/master-data/provinces',
    mock: true,
    isAuth: false,
  });

const getAllCountries = async ({ ctx }) =>
  GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/country/list`,
    isBasic: true,
    isAuth: false,
  });

export default {
  getProvinces,
  getAllCountries,
  getDistrictsByProvince,
  getWardsByDistrict,
  getWardByCode,
  getDistrictByCode,
  getProvinceByCode,
  getAllCountriesMock,
  getAllProvinceMock,
  getRegions,
};

